import React, { useMemo } from 'react';

import { useTokenOptions } from '@etica-js/api/src/appState/hooks';
import { useAppContext } from '@etica-js/api/src/appState/state';

import form from '../../../assets/styles.module.scss';
import { TabSelect } from '../../ui/tabSelect';

type Props = {
    onTokenMethodChange: (method: string) => void;
    label?: string;
    className?: string;
    except?: ReadonlyArray<string>;
};

export const TokenSelector: React.FC<Props> = ({
    onTokenMethodChange,
    label,
    className,
    except,
}) => {
    const appCtx = useAppContext();
    const options = useTokenOptions(except);

    const defaultIndex = useMemo(() => {
        const def = options.findIndex(
            (opt) => opt.value === appCtx.settings?.values?.default_token_method
        );

        return def > -1 ? def : undefined;
    }, [options, appCtx.settings]);

    return (
        <div className={className ?? form.fieldset}>
            <label htmlFor="">{label ?? 'Send Verification Token Via'}</label>

            <TabSelect
                options={options}
                onChange={(opt) =>
                    onTokenMethodChange(
                        opt?.value ||
                            appCtx.settings?.values?.default_token_method ||
                            'sms'
                    )
                }
                defaultIndex={defaultIndex}
            />
        </div>
    );
};
