import { Confirmation, Stage } from '.';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { toast } from 'react-toastify';

import {
    createTopup,
    createTopupPresentment,
    fetchCurrencies,
} from '@etica-js/api/src/appState/actions';
import { useAppContext } from '@etica-js/api/src/appState/state';
import { useAuthContext } from '@etica-js/api/src/auth/state';
import { Currency } from '@etica-js/api/src/schema';

import form from '../../assets/styles.module.scss';
import { Aside, AsideProps } from '../ui/aside';
import { LoadingAnimation } from '../ui/loading';
import { currencyFormat } from '../widgets/formatting';

type Props = {
    onClose: AsideProps['onClose'];
};

export const Topup: React.FC<Props> = (props) => {
    const appCtx = useAppContext();
    const authCtx = useAuthContext();
    const [amount, setAmount] = useState(0);
    const [accountNumber, setAccountNumber] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('MPESA');
    const [phoneNumber, setPhoneNumber] = useState(authCtx.user?.phone);
    const [selectedCurrency, setSelectedCurrency] = useState('');
    const [transId, setTransId] = useState('');
    const [token, setToken] = useState('');
    const [loading, setLoading] = useState(false);
    const [complete, setComplete] = useState(false);
    const ref = useRef<HTMLSelectElement>(null);
    const [stage, setStage] = useState<Stage>(undefined);
    const isMobile = useCallback(
        () => ['MPESA', 'SASAPAY'].includes(paymentMethod),
        [paymentMethod]
    );
    const [currencies, setCurrencies] = useState<readonly Currency[]>([]);

    useEffect(() => {
        setAccountNumber(appCtx.ui.openModal.target);
        if (ref.current) {
            ref.current.value = appCtx.ui.openModal.target;
        }

        fetchCurrencies().then((result) => {
            setCurrencies(result.data ?? []);
        });
    }, [appCtx.ui.openModal.target]);

    const getAccount = useCallback(() => {
        return appCtx.process('GET_ACCOUNTS');
    }, [appCtx]);

    const getSelectedAccount = useMemo(
        () => getAccount()?.find((acc) => acc.number === accountNumber),
        [accountNumber, getAccount]
    );

    const diffCurrency = useMemo(
        () => selectedCurrency !== getSelectedAccount?.product.currency.code,
        [selectedCurrency, getSelectedAccount]
    );

    const onSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);

        let currentCurrency = selectedCurrency;
        if (isMobile()) {
            currentCurrency = 'KES';
            setSelectedCurrency(currentCurrency);
        }

        if (stage === undefined) {
            createTopupPresentment({
                amount,
                number: accountNumber,
                payment_method: paymentMethod,
                phoneNumber,
                currency: currentCurrency,
            })
                .then((result) => {
                    if (result.is_error) {
                        toast.error('Failed - ' + result.message);
                        return;
                    }

                    if (paymentMethod === 'SASAPAY') {
                        toast.success(
                            `Enter the token received from ${paymentMethod}`
                        );
                    }

                    setTransId(result?.transaction_id ?? '');
                    const exchangeRate = result?.exchange_rate;

                    setStage({
                        type: 'confirmation',
                        message: `Please confirm your details below`,
                        setToken:
                            paymentMethod === 'SASAPAY' ? setToken : undefined,
                        tokenLabel: `Enter token received from ${paymentMethod}`,
                        data: [
                            {
                                label: 'Account',
                                value: getAccount()?.find(
                                    (acc) => acc.number === accountNumber
                                )?.label,
                            },
                            {
                                label: 'Amount',
                                value: currencyFormat({
                                    value: amount,
                                    currency: selectedCurrency,
                                }),
                            },
                            { label: 'Payment Method', value: paymentMethod },
                            { label: 'Phone Number', value: phoneNumber },
                            ...(diffCurrency && exchangeRate !== 1
                                ? [
                                      {
                                          label: 'Exchange rate',
                                          value: exchangeRate
                                              ? exchangeRate > 1
                                                  ? exchangeRate
                                                  : 1 / exchangeRate
                                              : 'Exchange rate not available',
                                      },
                                      {
                                          label: 'Converted Amount',
                                          value: exchangeRate
                                              ? currencyFormat({
                                                    value:
                                                        amount * exchangeRate,
                                                    currency:
                                                        getSelectedAccount
                                                            ?.product.currency
                                                            .code,
                                                })
                                              : 'Exchange rate not available',
                                      },
                                  ]
                                : []),
                        ],
                    });
                })
                .finally(() => setLoading(false));

            return;
        }

        createTopup({
            amount,
            number: accountNumber,
            payment_method: paymentMethod,
            phoneNumber,
            transaction_id: transId,
            token,
            currency: selectedCurrency,
        })
            .then((result) => {
                appCtx.logger?.logUserAction('topup', !result.is_error);
                if (result.is_error) {
                    toast.error('Failed - ' + result.message);
                    return;
                }
                toast.success('Saved successfully');
                setComplete(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Aside onClose={props.onClose} success={complete}>
            <div className="flex flex-wrap p-3">
                <h3 className="text-xl font-bold mb-5 mt-10">Account Top Up</h3>

                <form onSubmit={onSubmit} className="w-full">
                    {stage && stage.type === 'confirmation' && (
                        <Confirmation {...stage} />
                    )}
                    <div className="w-full">
                        {!stage && (
                            <>
                                <div className={form.fieldset}>
                                    <label htmlFor="">Account</label>
                                    <select
                                        onChange={(e) =>
                                            setAccountNumber(
                                                e.currentTarget.value
                                            )
                                        }
                                        defaultValue={
                                            appCtx.ui.openModal.target
                                        }
                                        disabled={!!appCtx.ui.openModal.target}
                                        ref={ref}
                                        required
                                    >
                                        <option value="">SELECT ACCOUNT</option>
                                        {getAccount()?.map((acc) => (
                                            <option
                                                key={acc.number}
                                                value={acc.number}
                                            >
                                                {acc.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className={form.fieldset}>
                                    <label htmlFor="">Amount</label>
                                    <input
                                        type="number"
                                        onChange={(e) =>
                                            setAmount(
                                                parseFloat(
                                                    e.currentTarget.value
                                                )
                                            )
                                        }
                                        required
                                    />
                                </div>

                                <div className={form.fieldset}>
                                    <label htmlFor="">Payment Method</label>
                                    <select
                                        name="payment"
                                        onChange={(e) =>
                                            setPaymentMethod(
                                                e.currentTarget.value
                                            )
                                        }
                                        required
                                    >
                                        <option value="MPESA">M-pesa</option>
                                        <option value="BANK">Bank</option>
                                        <option value="SASAPAY">SasaPay</option>
                                    </select>
                                </div>
                                {isMobile() && (
                                    <div className={form.fieldset}>
                                        <label htmlFor="">Phone Number</label>
                                        <input
                                            type="text"
                                            defaultValue={authCtx.user?.phone}
                                            onChange={(e) =>
                                                setPhoneNumber(
                                                    e.currentTarget.value
                                                )
                                            }
                                            required
                                        />
                                    </div>
                                )}
                                {!isMobile() && (
                                    <div className={form.fieldset}>
                                        <label htmlFor="">
                                            Currency <br />
                                            <small>
                                                The currency you will use to
                                                transfer
                                            </small>
                                        </label>

                                        <select
                                            defaultValue={
                                                getSelectedAccount?.product
                                                    .currency.code
                                            }
                                            onChange={(e) =>
                                                setSelectedCurrency(
                                                    e.currentTarget.value
                                                )
                                            }
                                        >
                                            <option>Select currency</option>
                                            {currencies.map((currency) => (
                                                <option
                                                    key={currency.code}
                                                    value={currency.code}
                                                >
                                                    {currency.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                )}
                            </>
                        )}
                        <div className={form.fieldset}>
                            {stage && stage.type === 'confirmation' && (
                                <button
                                    className="secondary button w-full mb-2"
                                    onClick={() => setStage(undefined)}
                                >
                                    Back
                                </button>
                            )}
                            <button
                                type="submit"
                                className="primary button w-full"
                            >
                                <LoadingAnimation loading={loading} />{' '}
                                {stage ? 'Continue' : 'Save'}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </Aside>
    );
};
