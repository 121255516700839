import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const CommonContainer = () => {
    return (
        <>
            <ToastContainer />
        </>
    );
};
