import { ArrowLongRightIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';

import { WithCover } from '../layouts/covered';

export const NotFoundPage = () => (
    <WithCover>
        <div className="mt-10 mb-10 text-center">
            <h3 className="text-3xl font-bold mb-11">404: Not Found</h3>
            <div className="mb-10">
                <span className="text-base">URL could not be found.</span>
            </div>
            <div>
                <Link className="primary button" to="/" role="button">
                    <ArrowLongRightIcon /> Back Home
                </Link>
            </div>
        </div>
    </WithCover>
);
