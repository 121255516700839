import React from 'react';

import styles from '../assets/styles.module.scss';
import { NavBar } from '../components/navigation/navbar';

type Props = {
    children: JSX.Element;
};

export const WithCover: React.FC<Props> = (props) => {
    return (
        <div>
            <NavBar />
            <section
                className={`${styles.cover} flex flex-wrap justify-center items-center`}
            >
                <div className={`${styles.loginContainer}`}>
                    <div className={`${styles.loginSection} md:max-w-md`}>
                        {props.children}
                    </div>
                </div>
            </section>
        </div>
    );
};
