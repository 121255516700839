import { useContext, useEffect, useMemo } from 'react';
import {
    BrowserRouter,
    Route,
    Routes,
    UNSAFE_RouteContext,
    useLocation,
} from 'react-router-dom';

import { useAppContext } from '@etica-js/api/src/appState/state';
import { AuthStatus } from '@etica-js/api/src/auth/state';

import { NotFoundPage } from '../pages/NotFound';
import { ApplicationDetails, ApplicationType } from '../pages/applications';
import { Application } from '../pages/applications/form';
import { Complete, Invest, Resume } from '../pages/applications/invest';
import {
    CreatePassword,
    VerifyEmail,
    VerifyKYC,
    VerifyPhone,
} from '../pages/applications/verification';
import { ForgotPassword, ResetByLink } from '../pages/auth/forgot-password';
import { Login } from '../pages/auth/login';
import { ResetPartnerSecret } from '../pages/auth/partner';
import {
    AddBank,
    AddNextOfKin,
    DashboardIndex,
    DashboardPage,
    InvestmentPage,
    KycPage,
    ProfilePage,
    TwoFactorLink,
    TwoFactorToken,
} from '../pages/dashboard';
import { HomePage } from '../pages/home';
import { ErrorBoundary } from './errors';

export function useRoutePattern() {
    const routeContext = useContext(UNSAFE_RouteContext);

    return useMemo(
        () =>
            routeContext.matches
                .map(({ route: { path } }) => path)
                .filter(Boolean)
                .join('/')
                .replaceAll(/\/\*?\//g, '/'),
        [routeContext.matches]
    );
}

const RoutesAnalytics = () => {
    const location = useLocation();
    const { logger } = useAppContext();

    useEffect(() => {
        const path = location.pathname?.replaceAll?.(/\d+/g, ':param');

        logger?.log({
            name: 'page_view',
            params: {
                page_path: path,
                page_title: path,
            },
        });
    }, [location, logger]);

    return null;
};

export const Router = () => (
    <BrowserRouter>
        <AuthStatus />
        <RoutesAnalytics />
        <Routes>
            <Route path="*" element={<NotFoundPage />} />
            <Route
                path="/"
                errorElement={<ErrorBoundary />}
                children={
                    <>
                        <Route path="/" element={<HomePage />} />
                        <Route path="auth">
                            <Route path="login" element={<Login />} />
                            <Route
                                path="forgot-password"
                                element={<ForgotPassword />}
                            />
                            <Route
                                path="reset-password/:username/:token"
                                element={<ResetByLink />}
                            />
                            <Route
                                path="partners/reset-secret/:client_id/:token"
                                element={<ResetPartnerSecret />}
                            />
                            <Route
                                path="two-factor/:token"
                                element={<TwoFactorLink />}
                            />
                        </Route>
                        <Route path="apply" element={<Application />}>
                            <Route index element={<ApplicationType />} />
                            <Route
                                path=":accountType"
                                element={<ApplicationDetails />}
                            />
                            <Route
                                path=":code/:key/verify-phone"
                                element={<VerifyPhone />}
                            />
                            <Route
                                path=":code/:key/verify-email"
                                element={<VerifyEmail />}
                            />
                            <Route
                                path=":code/:key/create-password"
                                element={<CreatePassword />}
                            />
                            <Route
                                path=":code/:key/kyc"
                                element={<VerifyKYC />}
                            />
                            <Route
                                path=":code/:key/invest"
                                element={<Invest />}
                            />
                            <Route
                                path=":code/:key/complete"
                                element={<Complete />}
                            />
                            <Route
                                path=":code/:key/resume"
                                element={<Resume />}
                            />
                        </Route>
                        <Route path="dashboard" element={<DashboardIndex />}>
                            <Route index element={<DashboardPage />} />
                            <Route path="profile" element={<ProfilePage />} />
                            <Route
                                path="profile/:code/add-bank-account"
                                element={<AddBank />}
                            />
                            <Route
                                path="profile/two-factor-auth"
                                element={<TwoFactorToken />}
                            />

                            <Route
                                path="profile/:code/add-next-of-kin"
                                element={<AddNextOfKin />}
                            />
                            <Route
                                path="profile/:code/upload-kyc"
                                element={<KycPage />}
                            />
                            <Route
                                path="investments"
                                element={<InvestmentPage />}
                            />
                        </Route>
                    </>
                }
            />
        </Routes>
    </BrowserRouter>
);
