import React from 'react';

import { useContextInitialization } from './hooks';

export const ContextInitializer: React.FC<{
    children: JSX.Element;
}> = ({ children }) => {
    useContextInitialization();

    return <>{children}</>;
};
