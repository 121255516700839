import React, { useState } from 'react';

import { Modal } from './modal';

type Props = {
    btnText: string | JSX.Element;
    btnClass: string;
    confirmationText: string;
    confirmBtnAction: () => void;
    confirmBtnText?: string;
};
export const Confirmation: React.FC<Props> = (props) => {
    const [showModal, setShowModal] = useState(false);
    return (
        <>
            <button
                className={props.btnClass}
                onClick={(evt) => {
                    evt.preventDefault();
                    setShowModal(true);
                }}
            >
                {props.btnText}
            </button>
            <Modal
                showModal={showModal}
                setShowModal={setShowModal}
                text={props.confirmationText}
                secondaryButtonAction={() => setShowModal(false)}
                secondaryButtonText="Cancel"
                primaryButtonAction={props.confirmBtnAction}
                primaryButtonText={props.confirmBtnText ?? 'Yes, Proceed'}
            ></Modal>
        </>
    );
};
