import { getStages, saveApplicationToContext, useFormNavigate } from '..';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import { useAppContext } from '@etica-js/api/src/appState/state';
import { post } from '@etica-js/api/src/framework/http';

import { ApplicationForm, Form } from '../form';
import styles from '../styles.module.scss';

export type Stages = ReadonlyArray<{ name: string; link: string; key: string }>;

export const VerifyEmail = () => {
    const { code, key } = useParams();
    const [stages, setStages] = useState<Stages>([]);
    const [email, setEmail] = React.useState('');
    const [token, setToken] = React.useState('');
    // const [requestToken, setRequestToken] = React.useState(false);
    const navigate = useFormNavigate();
    const appCtx = useAppContext();

    const sendTokenEmail = async (emailAddr: string) => {
        if (!emailAddr) {
            // setRequestToken(true);
            return;
        }

        const resp = await post(
            `clients/applications/${code}/${key}/verify-email`,
            {
                data: {
                    email: emailAddr,
                },
            }
        );

        const data = await resp.json();
        if (!resp.ok) {
            toast.error(data?.Message ?? 'Could not verify email');
            return;
        }

        toast.success('An email has been sent to you with the token');
    };

    const fetchData = async () => {
        const data = await saveApplicationToContext(
            code ?? '',
            key ?? '',
            appCtx
        );

        if (!data.success) {
            toast.error(`Failed - ${data?.message}`);
            return;
        }

        setEmail(data.client?.email ?? '');
        sendTokenEmail(data.client?.email ?? '');

        const classification = data.client?.classification;
        if (classification) {
            setStages(getStages(classification));
        }
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code, key]);

    const form: Form = {
        groups: [
            {
                name: 'verify-phone',
                fields: [],
            },
        ],
    };

    const onEmailChanged = (event: React.FormEvent<HTMLInputElement>) => {
        setEmail(event.currentTarget.value);
        // setRequestToken(true);
    };

    const onSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        const resp = await post(
            `clients/applications/${code}/${key}/save-verify-email`,
            {
                data: {
                    email,
                    token,
                },
            }
        );
        const data = await resp.json();

        if (!resp.ok) {
            toast.error(
                data?.Message ??
                    'Token verification failed! Check token or request a new one'
            );
            return;
        }

        toast.success('Email verification complete!');
        navigateForward();
    };

    const navigateForward = () => {
        navigate(`${code}/${key}/create-password`);
    };

    const updateEmail = (event: React.FormEvent) => {
        event.preventDefault();

        sendTokenEmail(email);
    };

    return (
        <ApplicationForm
            stages={stages}
            currentStage="email"
            form={form}
            onSubmit={onSubmit}
            // onSkip={() => {
            //     navigateForward();
            // }}
        >
            <>
                <div className={styles.fieldGroup}>
                    <div className={styles.fieldset}>
                        <label htmlFor="">Enter Email Address</label>
                        <input
                            type="text"
                            name="email"
                            placeholder="Enter email address"
                            defaultValue={email}
                            onChange={onEmailChanged}
                        />

                        <button
                            className={
                                styles.tokenButton +
                                ' secondary button w-full md:w-auto'
                            }
                            onClick={updateEmail}
                        >
                            <ArrowPathIcon className="h-5 inline" /> Request
                            token
                        </button>
                    </div>
                </div>

                <div className={styles.fieldGroup}>
                    <div className={styles.fieldset}>
                        <label htmlFor="">
                            Enter token from email sent to you
                        </label>
                        <input
                            type="text"
                            name="token"
                            placeholder="Enter token"
                            onChange={(e) => setToken(e.currentTarget.value)}
                        />
                    </div>
                </div>
            </>
        </ApplicationForm>
    );
};
