export function keys<T extends Record<string, unknown>>(obj: T): (keyof T)[] {
    return Object.keys(obj);
}

export function values<E, T extends Record<string, E>>(obj: T): E[] {
    return Object.values(obj);
}

export async function sleepForSeconds(durationSecs: number) {
    await new Promise((resolve) => setTimeout(resolve, durationSecs * 1000));
}

export function retryWithExponentialBackoff<T>(
    fn: () => T,
    maxAttempts = 5,
    baseDelayMs = 1000
) {
    let attempt = 1;

    const execute: () => Promise<T> = async () => {
        try {
            return await fn();
        } catch (error) {
            if (attempt >= maxAttempts) {
                throw error;
            }

            const delayMs =
                attempt === 0 ? 0 : baseDelayMs * 2 ** (attempt - 1);

            await new Promise((resolve) => setTimeout(resolve, delayMs));

            attempt++;
            return execute();
        }
    };

    return {
        result: execute(),
        attempts: attempt,
    };
}

//Debounce function with return value using promises
// src: https://dev.to/jorensm/debounce-function-with-a-return-value-using-promises-1i14
export function debounce<T extends (...args: any) => any>(
    callback: T,
    delay: number,
    option: 'leading' | 'trailing' = 'trailing'
): (...args: Parameters<T>) => Promise<Awaited<ReturnType<T>>> {
    const state = {
        timer: undefined as ReturnType<typeof setTimeout> | undefined,
        invoked: false,
    };

    let timer: ReturnType<typeof setTimeout> | undefined;

    return (...args: Parameters<T>[]) => {
        return new Promise((resolve, reject) => {
            clearTimeout(timer);

            // handle leading
            if (option === 'leading' && !state.invoked) {
                state.invoked = true;
                resolve(callback(...args));
            }

            // wait for timer and then execute the function
            state.timer = setTimeout(() => {
                if (option === 'trailing' && !state.invoked) {
                    try {
                        resolve(callback(...args));
                    } catch (err) {
                        reject(err);
                    }
                }
                state.invoked = false;
            }, delay);
        });
    };
}
