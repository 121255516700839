import {
    CheckCircleIcon,
    ChevronDownIcon,
    ChevronRightIcon,
} from '@heroicons/react/24/solid';
import React from 'react';

import styles from './ui.module.scss';

export type AccordionProps = {
    children: JSX.Element;
    footer?: string | JSX.Element;
    title: string | JSX.Element;
    uploaded?: boolean;
};

export const Accordion: React.FC<AccordionProps> = ({
    children,
    footer,
    title,
    uploaded,
}) => {
    const [isOpen, setIsOpen] = React.useState(false);

    return (
        <div className={styles.accordion}>
            <div className={styles.header} onClick={() => setIsOpen(!isOpen)}>
                <div className="grow">{title}</div>
                <div className={styles.icon + ' flex'}>
                    {uploaded ? (
                        <CheckCircleIcon className="text-green-600" />
                    ) : (
                        ''
                    )}
                    {isOpen ? <ChevronRightIcon /> : <ChevronDownIcon />}
                </div>
            </div>
            {isOpen && (
                <>
                    {' '}
                    <div className={styles.body}> {children}</div>
                    {footer && (
                        <div
                            className={
                                styles.footer +
                                ' transition-transform ease-in-out delay-150'
                            }
                        >
                            {footer}
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
