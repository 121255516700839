import { logEvent as firebaseLogEvent } from 'firebase/analytics';

import { initializeFirebase } from './firebase';

type UserAction = {
    name: 'user_action';
    params: {
        action:
            | 'topup'
            | 'withdraw'
            | 'send_money'
            | 'pay_bill'
            | 'transfer'
            | 'new_account'
            | 'register';
        status?: boolean;
    };
};
type PageView = {
    name: 'page_view';
    params: {
        page_path: string;
        page_title: string;
        page_location?: string;
    };
};

type ScreenView = {
    name: 'screen_view';
    params: {
        screen_name: string;
        screen_class: string;
    };
};

export type Event = PageView | ScreenView | UserAction;

export interface Consumer {
    log(event: Event): void;
    canConsume(event: Event): boolean;
}

type WebAnalytics = ReturnType<typeof initializeFirebase>;

export class WebConsumer implements Consumer {
    constructor(private firebase: WebAnalytics) {}

    public log(event: Event) {
        if (!this.firebase?.analytics) {
            return;
        }
        firebaseLogEvent(
            this.firebase?.analytics,
            event.name as string,
            event.params
        );
    }

    public canConsume(event: Event) {
        return !!this.firebase?.analytics;
    }
}

export class ConsoleConsumer implements Consumer {
    constructor(private devMode?: boolean) {}

    public log(event: Event) {
        console.log(`[LOG:] ${event.name} \n${JSON.stringify(event.params)}`);
    }

    public canConsume(event: Event) {
        return !!this.devMode;
    }
}

export interface ILogger {
    log(event: Event): void;

    logUserAction(
        action: UserAction['params']['action'],
        success?: boolean
    ): void;
}

export class Logger {
    constructor(private consumers: Consumer[]) {}

    public log(event: Event) {
        this.consumers.forEach((consumer) => {
            if (!consumer.canConsume(event)) {
                return;
            }

            consumer.log(event);
        });
    }

    public logUserAction(
        action: UserAction['params']['action'],
        status?: boolean
    ) {
        this.log({
            name: 'user_action',
            params: {
                action,
                status,
            },
        });
    }
}
