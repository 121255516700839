import { getStages, saveApplicationToContext, useFormNavigate } from '..';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import { useAppContext } from '@etica-js/api/src/appState/state';
import { post } from '@etica-js/api/src/framework/http';

import { ApplicationForm, Form } from '../form';
import styles from '../styles.module.scss';
import { Stages } from './email';

export const VerifyPhone = () => {
    const { code, key } = useParams();
    const [phone, setPhone] = React.useState('');
    const [token, setToken] = React.useState('');
    // const [requestToken, setRequestToken] = React.useState(false);
    const [stages, setStages] = useState<Stages>([]);
    const appCtx = useAppContext();
    const navigate = useFormNavigate();

    const sendTokenSMS = async (phoneNumber: string) => {
        if (!phoneNumber) {
            // setRequestToken(true);
            return;
        }

        const resp = await post(
            `clients/applications/${code}/${key}/verify-phone`,
            {
                data: {
                    phone: phoneNumber,
                },
            }
        );
        const data = await resp.json();
        if (!resp.ok) {
            toast.error(data?.Message ?? 'Could not verify phone number');
            return;
        }

        toast.success('An sms has been sent to your phone with the token');
    };

    const sendTokenDefault = () => {
        sendTokenSMS(phone);
    };

    const fetchData = async () => {
        const data = await saveApplicationToContext(
            code ?? '',
            key ?? '',
            appCtx
        );

        if (!data.success) {
            toast.error(`Failed - ${data?.message}`);
            return;
        }

        const classification = data.client?.classification;
        if (classification) {
            setStages(getStages(classification));
        }

        if (!data.success) {
            toast.error(`Failed - ${data?.message}`);
        }

        setPhone(data.client?.phone ?? '');
        sendTokenSMS(data.client?.phone ?? '');
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code, key]);

    const form: Form = {
        groups: [
            {
                name: 'verify-phone',
                fields: [],
            },
        ],
    };

    const onPhoneChanged = (event: React.FormEvent<HTMLInputElement>) => {
        setPhone(event.currentTarget.value);
        // setRequestToken(true);
    };

    const onSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        const resp = await post(
            `clients/applications/${code}/${key}/save-verify-phone`,
            {
                data: {
                    phone,
                    token,
                },
            }
        );
        const data = await resp.json();

        if (!resp.ok) {
            toast.error(
                data?.Message ??
                    'Token verification failed! Check token or request a new one'
            );
            return;
        }

        toast.success('Phone verification complete!');
        navigate(`/apply/${code}/${key}/verify-email`);
    };

    const updateNumber = (event: React.FormEvent) => {
        event.preventDefault();

        sendTokenDefault();
    };
    return (
        <ApplicationForm
            stages={stages}
            currentStage="phone"
            form={form}
            onSubmit={onSubmit}
        >
            <>
                <div className={styles.fieldGroup}>
                    <div className={styles.fieldset}>
                        <label htmlFor="">Enter phone number</label>
                        <input
                            type="text"
                            name="phone"
                            placeholder="Enter phone number"
                            defaultValue={phone}
                            onChange={onPhoneChanged}
                        />

                        <button
                            className={
                                styles.tokenButton +
                                ' secondary button w-full md:w-auto'
                            }
                            onClick={updateNumber}
                        >
                            <ArrowPathIcon className="h-5 inline" /> Request
                            token
                        </button>
                    </div>
                </div>

                <div className={styles.fieldGroup}>
                    <div className={styles.fieldset}>
                        <label htmlFor="">
                            Enter token from SMS sent to your phone
                        </label>
                        <input
                            type="text"
                            name="token"
                            placeholder="Enter token"
                            onChange={(e) => setToken(e.currentTarget.value)}
                        />
                    </div>
                </div>
            </>
        </ApplicationForm>
    );
};
