import { AccType, getStages, useFormNavigate } from '..';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { useAppContext } from '@etica-js/api/src/appState/state';
import { post } from '@etica-js/api/src/framework/http';

import { ApplicationForm, Form, InputElementEvent } from '../form';
import { PersonalDetailsProps, individualFields } from './individual';

export const JointForm: React.FC<PersonalDetailsProps> = (props) => {
    const navigate = useFormNavigate();
    const appCtx = useAppContext();
    const [stages] = useState(
        getStages(props.accountType as AccType[number]['value'])
    );
    const [count, setCount] = useState(2);
    const MIN_FOR_GROUP_NAME = 4;

    const [formState, setFormState] = React.useState<{
        [key: string]: {
            [key: string]: string | number;
        };
    }>({});
    const [shortName, setShortName] = React.useState<string>('');

    const onSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        // @ts-ignore:next-line
        const source = window.APPLICATION_CLIENT ?? 'web';

        if (!shortName && count >= MIN_FOR_GROUP_NAME) {
            toast.error('Please provide a group name');
            return;
        }

        const resp = await post('clients/self-register/joint', {
            data: {
                joints: Object.values(formState),
                classification: props.accountType,
                short_name: shortName,
                source,
                referred_by: appCtx.application?.referred_by ?? '',
            },
        });

        const data = await resp.json();

        if (resp.ok) {
            toast.success('Saved successfully');
            navigate(`${data.code}/${data.key}/verify-phone`);
            return;
        }

        toast.error(`Failed - ${data?.Message}`);
    };

    const saveInput = (e: InputElementEvent, group: string) => {
        let state = formState;

        if (!state[group]) {
            state[group] = {};
        }

        state[group][e.currentTarget.name] = e.currentTarget.value;

        setFormState(state);
    };

    const addSig = (event?: React.FormEvent<HTMLButtonElement>) => {
        event?.preventDefault();
        event?.stopPropagation();
        const newCount = count + 1;
        setCount(newCount);

        setForm({
            ...form,
            groups: [
                ...form.groups,
                {
                    name: `${newCount}`,
                    title: `Signatory ${newCount}`,
                    fields: individualFields(saveInput, props.accountType),
                },
            ],
        });
    };

    const [form, setForm] = useState<Form>({
        groups: [
            {
                name: 'combined',
                title: '',
                fields: [
                    {
                        type: 'text',
                        name: 'short_name',
                        placeholder: 'You can give the group a name',
                        onChange: (e) => setShortName(e.currentTarget.value),
                        label: 'Group name',
                        optional: count < MIN_FOR_GROUP_NAME,
                    },
                ],
            },
            {
                name: '1',
                title: 'Signatory 1',
                fields: individualFields(saveInput, props.accountType),
            },
            {
                name: '2',
                title: 'Signatory 2',
                fields: individualFields(saveInput, props.accountType),
            },
        ],
    });

    return (
        <ApplicationForm
            stages={stages}
            currentStage="personal"
            form={form}
            onSubmit={onSubmit}
        >
            <div className="flex">
                <button className="secondary button" onClick={addSig}>
                    Add Another Signatory
                </button>
            </div>
        </ApplicationForm>
    );
};
