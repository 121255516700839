import { useFormNavigate } from '.';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, Outlet, useSearchParams } from 'react-router-dom';

import { useCurrentWidth } from '@etica-js/utils/src/window_width';

import { LoadingAnimation } from '../../components/ui/loading';
import { Page } from '../../layouts/page';
import styles from './styles.module.scss';

type HeaderProps = {
    stages: ReadonlyArray<{ link: string; name: string; key: string }>;
    currentStage: string;
};

export type InputElementEvent = React.FormEvent<
    HTMLInputElement | HTMLSelectElement
>;

export const Header: React.FC<HeaderProps> = (props) => {
    const [searchParams] = useSearchParams();

    const navigation = useFormNavigate();

    useEffect(() => {
        // @ts-ignore
        window.ReactNativeWebView?.postMessage?.(
            'SCROLL_HEIGHT:' + document.documentElement.scrollHeight.toString()
        );
    }, [navigation]);

    const progress = useMemo(() => {
        const totalStages = props.stages.length;
        const currentStage = props.stages.find(
            (stage) => stage.key === props.currentStage
        );
        const currentStageIndex = currentStage
            ? props.stages.indexOf(currentStage) + 1
            : 0;

        return {
            totalStages,
            currentStageIndex,
            currentStage,
        };
    }, [props.currentStage, props.stages]);

    const SmallHeader = (
        <div className={`${styles.stages} flex flex-wrap w-full mb-0`}>
            <div>
                {progress.totalStages > 1 && (
                    <span className="uppercase font-bold">
                        {progress.currentStageIndex} / {progress.totalStages}
                    </span>
                )}
                <span className="ml-2 uppercase font-bold">
                    {progress.currentStage?.name}
                </span>
            </div>
            <hr className="w-full" />
        </div>
    );
    const width = useCurrentWidth();
    const shouldShowSmallMenu = useMemo(() => {
        return searchParams.get('embedded') || width < 768;
    }, [width, searchParams]);

    return shouldShowSmallMenu ? (
        <>{SmallHeader}</>
    ) : (
        <div className={`${styles.stages} flex flex-wrap w-full mb-10`}>
            {props.stages.map((stage) => (
                <Link
                    className={
                        styles.applicationStage +
                        ` ${
                            stage.key === props.currentStage
                                ? styles.applicationStageActive
                                : ''
                        }`
                    }
                    to="#"
                    key={stage.name}
                >
                    {stage.name}
                </Link>
            ))}
            <hr className="w-full" />
        </div>
    );
};

export const Application = () => {
    const [searchParams] = useSearchParams();

    const page = (
        <div className={`${styles.applicationPane}`}>
            <Outlet />
        </div>
    );

    return searchParams.get('embedded') ? <>{page}</> : <Page>{page}</Page>;
};

type InputField = {
    type:
        | 'text'
        | 'number'
        | 'tel'
        | 'email'
        | 'date'
        | 'password'
        | 'checkbox';
    label?: string;
    placeholder?: string;
    onChange: (event: InputElementEvent, group: string) => void;
    optional?: boolean;
    value?: string;
};

export type SelectOptions = ReadonlyArray<{
    value: string | number;
    label: string;
}>;

type SelectField = {
    type: 'select';
    options: SelectOptions | (() => Promise<SelectOptions>);
    onChange: (event: InputElementEvent, group: string) => void;
    optional?: boolean;
};

type FieldType = InputField | SelectField;

type FieldDef = FieldType & {
    name: string;
    label?: string;
    children?: undefined;
    hide?: boolean;
};

type MultipleField = {
    name: string;
    label?: string;
    hide?: boolean;
    children: FieldDef[];
};

type Field = MultipleField | FieldDef;

export type Form = {
    groups: ReadonlyArray<{
        name: string;
        title?: string;
        fields: ReadonlyArray<Field>;
    }>;
};

const renderLabel = (f: { label?: string; optional?: boolean }) => {
    return f.label ? (
        <label className="grow md:grow-0">
            {f.label}{' '}
            {f.optional ? null : <span className={styles.required}>*</span>}{' '}
        </label>
    ) : (
        <></>
    );
};

const DisplaySingleField: React.FC<{
    f: FieldDef;
    group: string;
    hideLabel?: boolean;
}> = (props) => {
    const [options, setOptions] = useState<SelectOptions>([]);

    const { f } = props;

    useEffect(() => {
        if (f.type !== 'select') {
            return;
        }

        if (typeof f.options === 'function') {
            f.options().then((opts) => {
                setOptions(opts);
            });
            return;
        }

        setOptions(f.options);
    }, [f]);

    const renderField = (f: FieldDef, hideLabel?: boolean) => {
        const onChange = (event: InputElementEvent) => {
            f.onChange(event, props.group);
        };

        switch (f.type) {
            case 'text':
            case 'number':
            case 'tel':
            case 'email':
            case 'checkbox':
                return (
                    !f.hide && (
                        <div className={styles.inputGroup} key={f.name}>
                            {!hideLabel && renderLabel(f)}

                            <input
                                type={f.type}
                                placeholder={f.placeholder}
                                name={f.name}
                                value={f.value}
                                onChange={onChange}
                                required={!f.optional}
                            />
                        </div>
                    )
                );
            case 'select':
                return (
                    !f.hide && (
                        <div className={styles.inputGroup} key={f.name}>
                            {!hideLabel && renderLabel(f)}

                            <select
                                onChange={onChange}
                                name={f.name}
                                required={!f.optional}
                            >
                                {options.map((opt) => (
                                    <option key={opt.value} value={opt.value}>
                                        {opt.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )
                );
            default:
                return <></>;
        }
    };

    return <>{renderField(props.f, props.hideLabel)}</>;
};

const DisplayField: React.FC<{ field: Field; group: string }> = (props) => {
    const { field, group } = props;

    return (
        <fieldset className={styles.fieldset}>
            {!field.hide && renderLabel(field)}

            {field?.children ? (
                <div className={styles.inputGroup + ' ' + styles.pr0}>
                    {field.children.map((f) => (
                        <DisplaySingleField key={f.name} f={f} group={group} />
                    ))}
                </div>
            ) : (
                <DisplaySingleField f={field} group={group} hideLabel={true} />
            )}
        </fieldset>
    );
};

const AppForm = (props: {
    form: Form;
    children?: JSX.Element;
    onSkip?: (event: React.FormEvent) => void;
    onSubmit: (event: React.FormEvent) => void;
    loading?: boolean;
}) => {
    return (
        <div className="flex flex-wrap w-full">
            <form
                className={styles.form + ' flex flex-wrap w-full'}
                onSubmit={props.onSubmit}
            >
                {props.form.groups.map((group) => (
                    <div key={group.name} className={styles.fieldGroup}>
                        {group.title && (
                            <h3 className="text-2xl font-semibold">
                                {group.title}
                            </h3>
                        )}

                        {group.fields.map((field) => (
                            <DisplayField
                                key={field.name}
                                field={field}
                                group={group.name}
                            />
                        ))}
                    </div>
                ))}
                {props.children}
                <div
                    className={`${styles.fieldGroup} ${styles.submitGroup} flex flex-wrap`}
                >
                    <fieldset
                        className={styles.fieldset}
                        style={{
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        {props.onSkip ? (
                            <button
                                className="secondary button"
                                onClick={props.onSkip}
                            >
                                SKIP
                            </button>
                        ) : (
                            <span></span>
                        )}
                        <button
                            className="primary button"
                            style={{ flexGrow: 0 }}
                            type="submit"
                        >
                            <LoadingAnimation
                                loading={props.loading ?? false}
                            />
                            NEXT
                        </button>
                    </fieldset>
                </div>
            </form>
        </div>
    );
};

type FormProps = HeaderProps & {
    form: Form;
    children?: JSX.Element;
    onSubmit: (event: React.FormEvent) => void;
    onSkip?: (event: React.FormEvent) => void;
    loading?: boolean;
};

export const ApplicationForm: React.FC<FormProps> = (props) => {
    const { stages, form, currentStage } = props;

    return (
        <>
            <Header stages={stages} currentStage={currentStage} />
            <AppForm
                form={form}
                onSubmit={props.onSubmit}
                onSkip={props.onSkip}
                loading={props.loading}
            >
                {props.children}
            </AppForm>
        </>
    );
};
