// For testing mobile apps, we want to send API requests back to the same IP address that webview page loads from
const page_url = window?.location?.origin;

export const BASE_URL =
    process.env.REACT_APP_USE_WEB_URL && page_url
        ? page_url
        : process.env.REACT_APP_API_URL;

export const WEBAPP_URL = `${BASE_URL}`;

export function getCookie(name: string) {
    let cookieValue: null | string = null;

    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === name + '=') {
                cookieValue = decodeURIComponent(
                    cookie.substring(name.length + 1)
                );
                break;
            }
        }
    }
    return cookieValue;
}
