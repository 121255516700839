// @ts-ignore
import StackdriverErrorReporter from './stackdriver';

type Options = {
    key: string;
    projectId: string;
    version: string;
    allowedEnvs: readonly string[];
};

export const initialize = (app_name: string, options: Options) => {
    const envs = options?.allowedEnvs || [];

    const currentEnv = process.env.NODE_ENV ?? 'production';

    if ((envs && !envs.includes(currentEnv)) || !options.key) {
        return { report: console.error };
    }

    try {
        return initializeRemoteErrorReporting(app_name, options);
    } catch (e) {
        console.error(e);
        return { report: console.error };
    }
};

function initializeRemoteErrorReporting(app_name: string, options: Options) {
    const errorHandler = new StackdriverErrorReporter();
    errorHandler.start({
        key: options.key,
        projectId: options.projectId,

        service: `etica-js:${app_name}`,

        version: options.version,

        reportUncaughtExceptions: true,
        // Set to false to prevent reporting unhandled exceptions, default: `true`.

        // reportUnhandledPromiseRejections: true
        // Set to false to prevent reporting unhandled promise rejections, default: `true`.

        // disabled: true
        // Set to true to not send error reports, this can be used when developing locally, default: `false`.

        // context: {user: 'user1'}
        // You can set the user later using setUser()
    });

    return errorHandler;
}
