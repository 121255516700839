import { BASE_URL, getCookie } from './cookie';
import * as storage from './storage';
import { getToken, saveToken } from './token';
import { getPlatform, uuid } from './utils';

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

type JsonPrimitive = string | number | boolean;

export type JsonObject = { [key: string]: JsonPrimitive };
type HttpInput = {
    headers?: JsonObject;
    params?: JsonObject;
    content?: 'multipart' | 'json';
    data?:
        | {
              [key: string]: JsonPrimitive | ReadonlyArray<JsonObject>;
          }
        | FormData;
};
type StatusListener = undefined | ((status: boolean) => void);

let authStatusListener: StatusListener = undefined;

export const setAuthStatusListener = (listener: StatusListener) => {
    authStatusListener = listener;
};

const getDeviceId = async (): Promise<string> => {
    const key = 'device_unique_id';

    let deviceId = await storage.getItem<string>(key);

    if (!deviceId) {
        deviceId = `${getPlatform().platform}:${uuid()}`;
        storage.setItem(key, deviceId);
    }

    return deviceId;
};

export const getDefaultHeaders = async (
    isJson: boolean,
    skipAuth?: boolean
) => {
    const csrf = getCookie('csrftoken');
    const bearerToken = await getToken('access');

    const defaultHeaders: {
        Authorization?: string;
        'X-CSRFToken'?: string;
        'Content-type'?: string;
        'X-Device-Id': string;
        'X-Version': string;
        'X-Platform': string;
    } = {
        'X-Device-Id': await getDeviceId(),
        'X-Version': getPlatform().version,
        'X-Platform': getPlatform().platform,
    };

    if (bearerToken && !skipAuth) {
        defaultHeaders['Authorization'] = `Bearer ${bearerToken}`;
    }

    if (csrf) {
        defaultHeaders['X-CSRFToken'] = csrf;
    }

    if (isJson) {
        defaultHeaders['Content-type'] = 'application/json';
    }

    return defaultHeaders;
};

export const getFullUrl = (url: string) => {
    return `${BASE_URL}/api/${url}`;
};

export const encodeUrlParams = (paramsIn?: JsonObject) => {
    const esc = encodeURIComponent;
    const params = paramsIn ?? {};
    return paramsIn
        ? '?' +
              Object.keys(params)
                  .map((k) => `${esc(k)}=${esc(params[k])}`)
                  .join('&')
        : '';
};

export const refreshAccessToken = async () => {
    const token = await getToken('refresh');

    if (!token) {
        return;
    }

    const resp = await post(
        'token/refresh',
        {
            data: {
                refresh: token,
            },
        },
        { dontRetry: true }
    );

    const data = await resp.json();

    if (resp.ok && data.access) {
        saveToken('access', data.access);

        if (data.refresh) {
            saveToken('refresh', data.refresh);
        }

        return true;
    }
    return false;
};

type RequestOptions = {
    skipAuth?: boolean;
    dontRetry?: boolean;
};

export async function request(
    url: string,
    method: HttpMethod,
    input?: HttpInput,
    options?: RequestOptions
) {
    const content = input?.content;

    const isJson = !content || content === 'json';

    const qualifiedUrl = url.startsWith('http') ? url : getFullUrl(url);

    const query = encodeUrlParams(input?.params);

    const jsonData = input?.data ? JSON.stringify(input?.data) : undefined;

    const getOptions = async () =>
        ({
            method,
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'include',
            redirect: 'follow',
            referrerPolicy: 'origin',
            headers: {
                ...(await getDefaultHeaders(isJson, options?.skipAuth)),
                ...input?.headers,
            },
            body: isJson ? jsonData : (input?.data as FormData),
        } as const);

    const resp = await fetch(qualifiedUrl + query, await getOptions());

    if (!options?.dontRetry && resp.status === 401) {
        try {
            // error returned if token is invalid/expired
            if ((await resp.clone().json()).error_code === 'token_not_valid') {
                //refresh token if available, rerun request if new token is received
                if (await refreshAccessToken()) {
                    return await fetch(
                        qualifiedUrl + query,
                        await getOptions()
                    );
                } else {
                    authStatusListener?.(false);
                }
            }
        } catch (err) {
            // ignore
        }
    }

    return resp;
}

export async function get(
    url: string,
    input?: HttpInput,
    options?: RequestOptions
) {
    return request(url, 'GET', input, options);
}

export async function post(
    url: string,
    input?: HttpInput,
    options?: RequestOptions
) {
    return request(url, 'POST', input, options);
}
