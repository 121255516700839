import * as CurrencyFormat from 'react-currency-format';

import { dateFormat } from '@etica-js/utils/src/formatting';

export { dateFormat, currencyFormat } from '@etica-js/utils/src/formatting';

export const Currency = (props: {
    value: number | string;
    currency?: string | null;
}) => {
    return (
        // @ts-ignore
        <CurrencyFormat
            value={props.value}
            prefix={props.currency ? props.currency + ' ' : ''}
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator=","
            displayType="text"
            // @ts-ignore
            renderText={(value) => <>{value}</>}
        />
    );
};

export const DateFormat: React.FC<{ date: string | Date; format?: string }> = (
    props
) => {
    return <>{dateFormat(props.date, props.format)}</>;
};
