import { Confirmation, Stage } from '.';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import {
    sendMoney,
    sendMoneyPresentment,
} from '@etica-js/api/src/appState/actions';
import { useFeatures } from '@etica-js/api/src/appState/hooks';
import { useAppContext } from '@etica-js/api/src/appState/state';

import form from '../../assets/styles.module.scss';
import { Aside, AsideProps } from '../ui/aside';
import { LoadingAnimation } from '../ui/loading';
import { currencyFormat } from '../widgets/formatting';
import { TokenSelector } from './parts/token';

type Props = {
    onClose: AsideProps['onClose'];
};

export const SendMoney: React.FC<Props> = (props) => {
    const appCtx = useAppContext();
    const [amount, setAmount] = useState(0);
    const [accountNumber, setAccountNumber] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [complete, setComplete] = useState(false);
    const ref = useRef<HTMLSelectElement>(null);
    const [stage, setStage] = useState<Stage>(undefined);
    const [tokenMethod, setTokenMethod] = useState('sms');
    const [token, setToken] = useState('');
    const { enable_transaction_pin } = useFeatures();

    useEffect(() => {
        if (enable_transaction_pin) {
            setTokenMethod('pin');
        }
    }, [enable_transaction_pin]);

    useEffect(() => {
        setAccountNumber(appCtx.ui.openModal.target);
        if (ref.current) {
            ref.current.value = appCtx.ui.openModal.target;
        }
    }, [appCtx.ui.openModal.target]);

    const getAccount = useCallback(() => {
        return appCtx.process('GET_ACCOUNTS');
    }, [appCtx]);

    const onSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);

        if (stage === undefined) {
            sendMoneyPresentment({
                phoneNumber,
                client_account: accountNumber,
                amount,
                tokenMethod,
            })
                .then((result) => {
                    if (result.is_error) {
                        toast.error(result.message ?? 'Unknown error');
                        return;
                    }

                    const fees =
                        result.data?.fees.map((fee) => ({
                            label: fee.name,
                            value: currencyFormat({ value: fee.amount }),
                        })) ?? [];

                    setStage({
                        type: 'confirmation',
                        message: 'Please confirm your transaction below',
                        tokenMethod,
                        setToken,
                        data: [
                            {
                                label: 'Account',
                                value: getAccount()?.find(
                                    (acc) => acc.number === accountNumber
                                )?.label,
                            },
                            {
                                label: 'Amount',
                                value: currencyFormat({ value: amount }),
                            },
                            { label: 'Phone Number', value: phoneNumber },
                            ...fees,
                        ],
                    });
                })
                .finally(() => setLoading(false));

            return;
        }

        sendMoney({
            phoneNumber,
            client_account: accountNumber,
            amount,
            token,
            token_method: tokenMethod,
        })
            .then((result) => {
                appCtx.logger?.logUserAction('send_money', !result.is_error);
                if (result.is_error) {
                    toast.error('Failed - ' + result.message);
                    return;
                }
                toast.success('Saved successfully');
                setComplete(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Aside onClose={props.onClose} success={complete}>
            <div className="flex flex-wrap p-3">
                <h3 className="text-xl font-bold mb-5 mt-10">Send Money</h3>

                <form onSubmit={onSubmit} className="w-full">
                    {stage && stage.type === 'confirmation' && (
                        <Confirmation {...stage} />
                    )}
                    <div className="w-full">
                        {!stage && (
                            <>
                                <div className={form.fieldset}>
                                    <label htmlFor="">Account</label>
                                    <select
                                        onChange={(e) =>
                                            setAccountNumber(
                                                e.currentTarget.value
                                            )
                                        }
                                        defaultValue={
                                            appCtx.ui.openModal.target
                                        }
                                        disabled={!!appCtx.ui.openModal.target}
                                        ref={ref}
                                        required
                                    >
                                        <option value="">SELECT ACCOUNT</option>
                                        {getAccount()?.map((acc) => (
                                            <option
                                                key={acc.number}
                                                value={acc.number}
                                            >
                                                {acc.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className={form.fieldset}>
                                    <label htmlFor="">Amount</label>
                                    <input
                                        type="number"
                                        onChange={(e) =>
                                            setAmount(
                                                parseFloat(
                                                    e.currentTarget.value
                                                )
                                            )
                                        }
                                        required
                                    />
                                </div>
                                <div className={form.fieldset}>
                                    <label htmlFor="">Phone Number</label>
                                    <input
                                        type="text"
                                        onChange={(e) =>
                                            setPhoneNumber(
                                                e.currentTarget.value
                                            )
                                        }
                                        required
                                    />
                                </div>
                                {!enable_transaction_pin && (
                                    <TokenSelector
                                        onTokenMethodChange={setTokenMethod}
                                    />
                                )}
                            </>
                        )}
                        <div className={form.fieldset}>
                            {stage && stage.type === 'confirmation' && (
                                <button
                                    className="secondary button w-full mb-2"
                                    onClick={() => setStage(undefined)}
                                >
                                    Back
                                </button>
                            )}
                            <button
                                type="submit"
                                className="primary button w-full"
                            >
                                <LoadingAnimation loading={loading} />{' '}
                                {stage
                                    ? 'Confirm'
                                    : enable_transaction_pin
                                    ? 'Confirm'
                                    : 'Send Token'}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </Aside>
    );
};
