/* eslint-disable jsx-a11y/anchor-is-valid */
import {
    ArrowRightOnRectangleIcon,
    ArrowTrendingUpIcon,
    BanknotesIcon,
    Bars4Icon,
    Cog6ToothIcon,
    HomeIcon,
    UserIcon,
} from '@heroicons/react/24/outline';
import { useCallback, useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';

import { logoutUser } from '@etica-js/api/src/auth/actions';
import { useAuthContext } from '@etica-js/api/src/auth/state';
import logo from '@etica-js/assets/src/images/logo.svg';
import { useCurrentWidth } from '@etica-js/utils/src/window_width';

import styles from './styles.module.scss';

const mountedStyle = {
    maxHeight: '100vh',
};

export const SideNav = () => {
    const isActive = (state: { isActive: boolean }) =>
        state.isActive ? styles.active : '';

    const auth = useAuthContext();
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);
    const windowWidth = useCurrentWidth();

    const hideMenu = () => setMenuOpen(false);

    const logout = (e: { preventDefault: () => void }) => {
        e.preventDefault();

        logoutUser().then(() => {
            auth.dispatch?.({ type: 'RESET' });
            navigate('/auth/login');
        });
    };

    const shouldShowMenu = useCallback(() => {
        if (windowWidth >= 768) {
            return true;
        }

        return menuOpen;
    }, [windowWidth, menuOpen]);

    const styling = {
        maxHeight: 0,
        ...(shouldShowMenu() ? mountedStyle : {}),
        transition: 'max-height ease .5s',
    };

    return (
        <nav className={styles.sideNav + ' bg-white w-full md:w-1/4 lg:w-1/5'}>
            <div className="w-full ml-5 mr-5 mt-2 mb-2">
                <div className="flex content-center">
                    <button
                        className="mr-2 pb-4 md:hidden"
                        onClick={() => setMenuOpen(!menuOpen)}
                        aria-label="Main menu"
                    >
                        <Bars4Icon className="w-10" />
                    </button>
                    <Link
                        className="w-full block mt-5"
                        style={{ height: 80 }}
                        to="/dashboard"
                    >
                        <img src={logo} alt="Logo" />
                    </Link>
                </div>

                <div className="flex flex-wrap w-full" style={styling}>
                    <div className={'flex flex-wrap text-gray-500 w-full'}>
                        <div className="w-full mt-5 mb-5">
                            <ul>
                                <li>
                                    <NavLink
                                        className={isActive}
                                        to="/dashboard"
                                        onClick={hideMenu}
                                        end
                                    >
                                        <HomeIcon /> Dashboard
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className={isActive}
                                        onClick={hideMenu}
                                        to="/dashboard/investments"
                                    >
                                        <BanknotesIcon /> My Investments
                                    </NavLink>
                                </li>
                                {false && (
                                    <li>
                                        <a href="">
                                            <ArrowTrendingUpIcon /> Investment
                                            products
                                        </a>
                                    </li>
                                )}
                                <li>
                                    <NavLink
                                        onClick={hideMenu}
                                        className={isActive}
                                        to="/dashboard/profile"
                                    >
                                        <UserIcon /> My profile
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className={styles.spacer}></div>

                        <div className="w-full mt-5 mb-5">
                            <hr className="" />
                            <ul className="py-4">
                                {false && (
                                    <li>
                                        <a href="">
                                            <Cog6ToothIcon /> Settings
                                        </a>
                                    </li>
                                )}
                                <li>
                                    <a
                                        style={{ cursor: 'pointer' }}
                                        onClick={logout}
                                    >
                                        <ArrowRightOnRectangleIcon /> Logout
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};
