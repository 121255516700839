import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UAParser } from 'ua-parser-js';

import { useAppUtils, useFeatures } from '@etica-js/api/src/appState/hooks';
import { useAppContext } from '@etica-js/api/src/appState/state';
import { post } from '@etica-js/api/src/framework/http';

import { LoginPane } from '../../components/auth/login';

export const Login = () => {
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [token, setToken] = React.useState('');
    const [isEnteringPassword, setIsEnteringPassword] = React.useState(true);
    const navigate = useNavigate();
    const [params] = useSearchParams();
    const appCtx = useAppContext();
    const [loading, setLoading] = useState(false);
    const [tokenMethod, setTokenMethod] = useState(
        appCtx.settings?.values?.default_token_method
    );
    const { tokenless_login } = useFeatures(true);
    const { encrypt } = useAppUtils();

    useEffect(() => {
        setTokenMethod(appCtx.settings?.values?.default_token_method);
    }, [appCtx.settings?.values?.default_token_method]);

    const requestToken = async () => {
        setLoading(true);
        const encrypted = encrypt(password);
        const resp = await post('token/request-2fa', {
            data: {
                username,
                password: encrypted.result,
                token_method: tokenMethod ?? 'sms',
                encrypted: encrypted.encrypted,
            },
        }).finally(() => setLoading(false));

        const data = await resp.json();

        if (resp.ok) {
            toast.success(`Please enter token from your ${tokenMethod}`);

            setIsEnteringPassword(false);
            return;
        }
        toast.error(data?.Message ?? 'Could not send token');
    };

    const authenticate = async () => {
        setLoading(true);
        const device = new UAParser().getResult();
        const encrypted = encrypt(password);

        const resp = await post(`token`, {
            data: {
                username,
                password: encrypted.result,
                token,
                encrypted: encrypted.encrypted,
            },
            params: {
                type: 'session',
            },
            headers: {
                'X-Device-Name': `${device.os.name} ${
                    device.os.version ?? ''
                } ${device.browser.name} ${
                    device.browser.version
                        ? '(' + device.browser.version + ')'
                        : ''
                }`,
            },
        }).finally(() => setLoading(false));

        const data = await resp.json();
        if (resp.ok) {
            toast.success('Logged in successfully');

            const redir = params.get('redir') ?? '/dashboard';

            navigate(redir);
            return;
        }

        toast.error(data?.Message ?? 'Could not complete login, try again');
    };

    const onSubmit = () => {
        if (isEnteringPassword && !tokenless_login) {
            requestToken();
            return;
        }

        if (tokenless_login && !token) {
            setToken('12345');
        }

        authenticate();
    };

    return (
        <LoginPane
            title="Welcome to Etica Capital"
            description="The investment platform of your choice where you imagine more"
            fields={[
                {
                    id: 'username',
                    type: 'text',
                    label: 'Email address or username',
                    placeholder: 'Enter email address or username',
                    onChange: (event) => {
                        setUsername(event.currentTarget.value);
                    },
                    hide: !isEnteringPassword,
                },
                {
                    id: 'password',
                    type: 'password',
                    label: 'Password',
                    placeholder: 'Enter password',
                    onChange: (event) => {
                        setPassword(event.currentTarget.value);
                    },
                    hide: !isEnteringPassword,
                },
                {
                    id: 'token_method',
                    type: 'token_method',
                    label: 'Send Login Token Via',
                    onChange: (value) => {
                        setTokenMethod(value?.toString() ?? '');
                    },
                    hide: !isEnteringPassword || tokenless_login,
                },
                {
                    id: 'token',
                    type: 'number',
                    label: 'Login Token',
                    placeholder: `Enter 6-digit token from ${tokenMethod}`,
                    onChange: (event) => {
                        setToken(event.currentTarget.value);
                    },
                    hide: isEnteringPassword,
                },
            ]}
            links={[
                { text: 'Create Account', location: '/apply' },
                { text: 'Forgot password', location: '/auth/forgot-password' },
            ]}
            submit={{
                value:
                    !tokenless_login && isEnteringPassword
                        ? 'Send Token'
                        : 'Login',
                fn: onSubmit,
                loading,
            }}
        />
    );
};
