import { ArrowPathIcon, LockClosedIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { requestToken } from '@etica-js/api/src/appState/actions';
import { useAuthContext } from '@etica-js/api/src/auth/state';

import form from '../../assets/styles.module.scss';
import { CreatePinModal } from '../widgets/create-pin';

type ConfirmationProps = {
    type: 'confirmation';
    title?: string;
    message?: string;
    data: ReadonlyArray<{ label: string; value?: string | number }>;
    children?: JSX.Element | JSX.Element[];
    setToken?: (token: string) => void;
    tokenMethod?: string;
    tokenLabel?: string;
};

export type Stage = undefined | ConfirmationProps;

export const getLabel = (
    choice: string | number,
    choices: ReadonlyArray<{ label: string; value: string | number }>
) => {
    return choices.find((c) => c.value === choice)?.label ?? '';
};

export const Confirmation: React.FC<ConfirmationProps> = (props) => {
    const [showPinModal, setShowPinModal] = useState(false);
    const authCtx = useAuthContext();

    const resendToken = () => {
        requestToken(props.tokenMethod ?? 'sms').then((resp) => {
            if (resp.ok) {
                toast.success('Token sent successfully');
                return;
            }

            toast.error('Failed to send token');
        });
    };
    return (
        <div className="flex flex-wrap w-full">
            <h3 className="text-xl font-bold mb-5">{props.title}</h3>

            <div className="w-full">
                <span>
                    <CreatePinModal
                        mode="new"
                        control={[showPinModal, setShowPinModal]}
                    />
                </span>
                <p className="mb-5">{props.message}</p>
                {props.data.map((item) => (
                    <div className="flex flex-wrap mb-2" key={item.label}>
                        <div className="w-1/2 font-semibold">{item.label}</div>
                        <div className="w-1/2">{item.value}</div>
                    </div>
                ))}

                {props.setToken && (
                    <div className={form.fieldset}>
                        <label htmlFor="">
                            {props.tokenMethod === 'pin'
                                ? 'Enter your secret pin'
                                : props.tokenLabel ??
                                  `Enter token from ${props.tokenMethod}`}
                            {props.tokenMethod &&
                                props.tokenMethod !== 'pin' && (
                                    <button
                                        type="button"
                                        className="tooltip"
                                        onClick={() => resendToken()}
                                    >
                                        {' '}
                                        <ArrowPathIcon className="w-5 text-blue-400" />
                                        <span className="tooltip-text">
                                            Resend token
                                        </span>
                                    </button>
                                )}
                            {props.tokenMethod &&
                                props.tokenMethod === 'pin' &&
                                !authCtx.user?.has_pin && (
                                    <button
                                        type="button"
                                        className="block text-red-500"
                                        onClick={() => setShowPinModal(true)}
                                    >
                                        <LockClosedIcon className="w-5 text-red-400 inline" />{' '}
                                        <span className="text-red-500">
                                            You have no secret pin. Click here
                                            to create one
                                        </span>
                                    </button>
                                )}
                        </label>
                        <input
                            type={
                                props.tokenMethod == 'pin'
                                    ? 'password'
                                    : 'number'
                            }
                            onChange={(e) =>
                                props.setToken?.(e.currentTarget.value)
                            }
                            required
                        />
                    </div>
                )}
            </div>

            {props.children}
        </div>
    );
};
