import { useEffect, useState } from 'react';
import React from 'react';
import { toast } from 'react-toastify';

import {
    createTransactionPin,
    requestToken,
} from '@etica-js/api/src/appState/actions';
import { useFeatures } from '@etica-js/api/src/appState/hooks';
import { useAppContext } from '@etica-js/api/src/appState/state';
import { useAuthContext } from '@etica-js/api/src/auth/state';
import { User } from '@etica-js/api/src/schema';

import { LoginForm } from '../auth/login';
import { Modal } from '../ui/modal';
import { Answer, SecretQuestions } from './secret-questions';

type Props = {
    mode: 'new' | 'reset';
    control?: [showModal: boolean, setShowModal: (show: boolean) => void];
};

export const CreatePinModal: React.FC<Props> = ({ mode, control }) => {
    const authCtx = useAuthContext();
    const appCtx = useAppContext();

    const [pin, setPin] = React.useState('');
    const [pin_repeat, setPinRepeat] = React.useState('');
    const [token, setToken] = React.useState('');
    const [isEnteringPassword, setIsEnteringPassword] = React.useState(true);
    const [tokenMethod, setTokenMethod] = useState(
        appCtx.settings?.values?.default_token_method
    );
    const { enable_transaction_pin } = useFeatures();
    const [loading, setLoading] = useState(false);

    const selfControl = useState(false);
    const [showModal, setShowModal] = control ?? selfControl;
    const [questions, setQuestions] = useState<ReadonlyArray<Answer>>();
    const [resetMethod, setResetMethod] = useState<'token' | 'questions'>(
        'questions'
    );

    useEffect(() => {
        mode === 'new' &&
            enable_transaction_pin &&
            setShowModal(authCtx.user?.has_pin === false);
    }, [authCtx.user?.has_pin, enable_transaction_pin, setShowModal, mode]);

    const getToken = () => {
        if (mode === 'reset' && resetMethod === 'token') {
            setIsEnteringPassword(false);
            return;
        }
        setLoading(true);
        requestToken(tokenMethod ?? 'sms', 'create secret pin')
            .then(() => {
                setIsEnteringPassword(false);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const savePin = () => {
        setLoading(true);
        createTransactionPin({
            token,
            pin,
            pin_repeat,
            answers: questions ?? [],
            mode,
            reset_method: resetMethod,
        })
            .then((res) => {
                if (res.is_error) {
                    toast.error(`An error occurred - ${res.message}`);
                    return;
                }

                toast.success('PIN has been saved successfully');
                setShowModal(false);

                authCtx.dispatch?.({
                    type: 'FETCH',
                    payload: { ...authCtx.user, has_pin: true } as User,
                });
            })
            .finally(() => setLoading(false));
    };

    const onSubmit = () => {
        if (pin.trim().length < 6) {
            toast.error('The PIN must be at least 6 digits long');
            return;
        }

        if (pin !== pin_repeat) {
            toast.error('The pin must match the repeatition');
            return;
        }

        if (/^-?\d+$/.test(pin) === false) {
            toast.error('The pin must be numeric');
            return;
        }

        if (!isEnteringPassword) {
            savePin();
            return;
        }
        getToken();
    };

    return (
        <Modal
            showModal={showModal}
            setShowModal={setShowModal}
            title={mode === 'new' ? 'Create a secret PIN' : 'Reset Secret PIN'}
            text=""
        >
            <>
                {mode === 'reset' && !questions && (
                    <div className="mb-5">
                        <label htmlFor="" className="text-gray-600 text-sm">
                            Select the method you want to use to reset your PIN
                        </label>
                        <select
                            className="px-2 py-2 border w-full rounded"
                            name="method"
                            onChange={(e) =>
                                setResetMethod(
                                    e.currentTarget.value as unknown as 'token'
                                )
                            }
                            required={true}
                            value={resetMethod}
                        >
                            <option value="questions">
                                Answer security questions
                            </option>
                            <option value="token">
                                Use a reset token sent by support
                            </option>
                        </select>
                    </div>
                )}
                {!questions && (
                    <SecretQuestions
                        onSelectQuestions={(answers) => setQuestions(answers)}
                    />
                )}

                {!!questions && (
                    <LoginForm
                        title=""
                        description="Create a 6 digit secret PIN that you will use to validate your transactions"
                        fields={[
                            {
                                id: 'pin',
                                type: 'password',
                                label: 'Enter 6 digit PIN',
                                placeholder: 'Enter 6 digit PIN',
                                onChange: (event) => {
                                    setPin(event.currentTarget.value);
                                },
                                hide: !isEnteringPassword,
                            },
                            {
                                id: 'pin_repeat',
                                type: 'password',
                                label: 'Repeat 6 digit PIN',
                                placeholder: 'Repeat 6 digit PIN',
                                onChange: (event) => {
                                    setPinRepeat(event.currentTarget.value);
                                },
                                hide: !isEnteringPassword,
                            },
                            {
                                id: 'token_method',
                                type: 'token_method',
                                label: 'Send Verification Token Via',
                                onChange: (value) => {
                                    setTokenMethod(value?.toString() ?? '');
                                },
                                hide:
                                    !isEnteringPassword ||
                                    resetMethod === 'token',
                            },
                            {
                                id: 'token',
                                type: 'number',
                                label:
                                    resetMethod === 'token'
                                        ? 'Enter your PIN reset token sent to your email'
                                        : 'Verification Token',
                                placeholder: `Enter your PIN reset token`,
                                onChange: (event) => {
                                    setToken(event.currentTarget.value);
                                },
                                hide: isEnteringPassword,
                            },
                        ]}
                        links={[]}
                        submit={{
                            value: isEnteringPassword
                                ? 'Send Token'
                                : 'Save PIN',
                            fn: onSubmit,
                            loading,
                        }}
                    />
                )}
            </>
        </Modal>
    );
};
