import { Tab } from '@headlessui/react';
import React, { useEffect, useMemo, useState } from 'react';

function classNames(...classes: Array<string>) {
    return classes.filter(Boolean).join(' ');
}

type Option = {
    value: string;
    label: string | JSX.Element;
};

type Props = {
    options: ReadonlyArray<Option>;
    onChange: (selected?: Option) => void;
    defaultIndex?: number;
};

export const TabSelect: React.FC<Props> = ({
    onChange,
    options,
    defaultIndex,
}) => {
    const [userSelected, setUserSelected] = useState(false);
    const [selected, setSelected] = useState<Option>();
    const handleChange = (idx: number, by_user = false) => {
        const curr = options && options[idx];
        if (curr) {
            setSelected(curr);
            onChange(curr);
        }

        if (by_user) {
            setUserSelected(!!by_user);
        }
    };

    const selectedIndex = useMemo(() => {
        const idx = options.findIndex((opt) => opt === selected);
        return idx > -1 ? idx : undefined;
    }, [options, selected]);

    useEffect(() => {
        const curr = defaultIndex ?? 0;
        if (curr && !userSelected) {
            handleChange(curr);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options, defaultIndex]);
    return (
        <div className="w-full">
            <Tab.Group
                onChange={(idx) => handleChange(idx, true)}
                defaultIndex={defaultIndex ?? 0}
                selectedIndex={selectedIndex}
            >
                <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
                    {options.map((opt) => (
                        <Tab
                            key={opt.value}
                            className={({ selected }) =>
                                classNames(
                                    'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                                    'ring-white/60 ring-offset-2 ring-offset-blue-200 focus:outline-none focus:ring-2',
                                    selected
                                        ? 'bg-white text-slate-700 shadow'
                                        : 'text-slate-700 hover:bg-white/[0.12] hover:text-white'
                                )
                            }
                        >
                            {opt.label}
                        </Tab>
                    ))}
                </Tab.List>
            </Tab.Group>
        </div>
    );
};
