import { v4 } from 'uuid';

export const uuid = () => crypto?.randomUUID?.() ?? v4();

export const getPlatform = () => {
    return {
        platform: 'web',
        version: '',
    };
};
