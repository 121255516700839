import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { fetchApplication } from '@etica-js/api/src/appState/actions';
import { useAppContext } from '@etica-js/api/src/appState/state';
import { neverGuardDefault } from '@etica-js/utils/src/typing';

import type { Form, InputElementEvent } from './form';
import { ApplicationForm } from './form';
import { CorporateForm } from './types/corporate';
import { IndividualForm } from './types/individual';
import { JointForm } from './types/joint';

const AccountTypes = [
    { value: 'individual', label: 'Local Individual' },
    { value: 'foreign_individual', label: 'Foreign individual' },
    { value: 'minor', label: 'Minor' },
    { value: 'sole_proprietor', label: 'Sole Proprietorship' },
    { value: 'joint', label: 'Joint account' },
    { value: 'self_help', label: 'Self help groups' },
    { value: 'partnership', label: 'Partnership' },
    { value: 'corporate', label: 'Corporate' },
    { value: 'foreign_corporate', label: 'Foreign corporate' },
] as const;

export const useFormNavigate = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    return (stage: string, save = true) => {
        const query = searchParams.get('embedded') ? '?embedded=true' : '';
        const dest = stage.startsWith('/') ? stage : `/apply/${stage}`;

        const link = `${dest}?${query}`
            .replace(/\/\//g, '/')
            .replace('??', '?');

        if (save && link.startsWith('/apply')) {
            // @ts-ignore
            window.ReactNativeWebView?.postMessage?.(
                `APPLICATION_RESUME:${link}`
            );
        }

        return navigate(link);
    };
};

export const ApplicationType = () => {
    const appCtx = useAppContext();
    const stages = [{ name: 'Application Type', link: '', key: 'type' }];
    const [searchParams] = useSearchParams();
    const referral_code = searchParams.get('referred_by') ?? '';

    const [accountType, setAccountType] = React.useState('individual');
    const [referrer, setReferrer] = React.useState(referral_code);

    const [termsAccepted, setTermsAccepted] = React.useState(false);
    const [phoneDisclaimerAccepted, setPhoneDisclaimerAccepted] =
        React.useState(false);

    const navigate = useFormNavigate();

    const onSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        if (!termsAccepted) {
            toast.error('Please accept the terms and conditions');
            return;
        }

        if (referrer && !appCtx.application?.referred_by) {
            appCtx.dispatch?.({
                type: 'SET_APPLICATION',
                payload: {
                    ...appCtx.application,
                    referred_by: referrer,
                    type: accountType,
                },
            });
        }

        navigate(`${accountType}`, false);
    };

    const onAccountChange = (event: InputElementEvent) => {
        setAccountType(event.currentTarget.value);
        appCtx.dispatch?.({
            type: 'SET_APPLICATION',
            payload: {
                ...appCtx.application,
                type: event.currentTarget.value,
                referred_by: referrer,
            },
        });
    };

    const onReferralChange = (event: InputElementEvent) => {
        setReferrer(event.currentTarget.value);
        appCtx.dispatch?.({
            type: 'SET_APPLICATION',
            payload: {
                ...appCtx.application,
                type: accountType,
                referred_by: event.currentTarget.value,
            },
        });
    };

    const onTermsChange = (event: InputElementEvent) => {
        setTermsAccepted(!termsAccepted);
    };

    const form: Form = {
        groups: [
            {
                name: 'Account Type',
                fields: [
                    {
                        name: 'account_type',
                        type: 'select',
                        label: 'Type of account',
                        onChange: onAccountChange,
                        options: AccountTypes,
                    },
                    {
                        name: 'referred_by',
                        type: 'text',
                        label: 'Referred by (Optional)',
                        value: referrer,
                        placeholder:
                            'Enter referral code or name of your financial adviser (Optional)',
                        optional: true,
                        onChange: onReferralChange,
                        hide: !!referral_code,
                    },
                ],
            },
        ],
    };

    return (
        <ApplicationForm
            stages={stages}
            currentStage="type"
            form={form}
            onSubmit={onSubmit}
        >
            <div className="flex flex-wrap w-full">
                <label className="w-full mb-5">
                    <input
                        type="checkbox"
                        onChange={onTermsChange}
                        checked={termsAccepted}
                        required
                    />
                    {'    '}
                    By continuing, you agree to our{' '}
                    <a
                        className="text-blue-500"
                        target="_blank"
                        href="https://eticacap.com/terms-of-use"
                        rel="noreferrer"
                    >
                        Terms Of Use
                    </a>{' '}
                    and{' '}
                    <a
                        className="text-blue-500"
                        target="_blank"
                        href="https://eticacap.com/privacy-policy"
                        rel="noreferrer"
                    >
                        Privacy Policy
                    </a>
                </label>

                <label className="w-full mb-5">
                    <input
                        type="checkbox"
                        onChange={() =>
                            setPhoneDisclaimerAccepted(!phoneDisclaimerAccepted)
                        }
                        checked={phoneDisclaimerAccepted}
                        required
                    />
                    {'    '}
                    By providing your phone number, you accept to receive
                    messages from us. Standard message and data rates apply.
                </label>
            </div>
        </ApplicationForm>
    );
};

export const ApplicationDetails: React.FC = () => {
    const { accountType } = useParams();

    switch (accountType) {
        case 'individual':
        case 'foreign_individual':
        case 'sole_proprietor':
        case 'minor':
            return <IndividualForm accountType={accountType} />;
        case 'joint':
        case 'self_help':
        case 'partnership':
            return <JointForm accountType={accountType} />;
        case 'corporate':
        case 'foreign_corporate':
            return <CorporateForm accountType={accountType} />;
        default:
            return <span>Account type {accountType} not supported</span>;
    }
};

export type AccType = typeof AccountTypes;

export const getStages = (
    applicationType: AccType[number]['value']
): ReadonlyArray<{ name: string; link: string; key: string }> => {
    const normal = [
        { name: 'Personal Information', link: '', key: 'personal' },
        { name: 'Verify Phone Number', link: '', key: 'phone' },
        { name: 'Verify Email', link: '', key: 'email' },
        { name: 'Create Password', link: '', key: 'password' },
        { name: 'KYC Documents', link: '', key: 'kyc' },
        { name: 'Invest', link: '', key: 'invest' },
    ];
    switch (applicationType) {
        case 'individual':
            return [
                { name: 'Personal Information', link: '', key: 'personal' },
                { name: 'Verify Phone Number', link: '', key: 'phone' },
                { name: 'Verify Email', link: '', key: 'email' },
                { name: 'Create Password', link: '', key: 'password' },
                { name: 'Invest', link: '', key: 'invest' },
            ];
        case 'minor':
        case 'foreign_individual':
        case 'sole_proprietor':
        case 'joint':
        case 'self_help':
        case 'partnership':
        case 'corporate':
        case 'foreign_corporate':
            return normal;
        default:
            return neverGuardDefault(applicationType, [
                { name: '', link: '', key: '' },
            ]);
    }
};

export const saveApplicationToContext = async (
    code: string,
    key: string,
    ctx: ReturnType<typeof useAppContext>
) => {
    const data = await fetchApplication(code ?? '', key ?? '');

    if (data.client) {
        ctx?.dispatch?.({
            type: 'SET_APPLICATION',
            payload: { type: data.client?.classification, client: data.client },
        });
    }

    return data;
};
