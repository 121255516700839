import { intervalToDuration } from 'date-fns';

export function timeSinceHuman(
    toDate: Date,
    fromDate: Date | undefined = undefined
) {
    const startDate = fromDate ?? new Date();

    const interval = intervalToDuration({ start: startDate, end: toDate });

    if (interval.years) {
        return `${interval.years} years${
            interval.months ? ` ${interval.months} months` : ''
        }`;
    }

    if (interval.months) {
        return `${interval.months} months${
            interval.days ? ` ${interval.days} days` : ''
        }`;
    }

    if (interval.days) {
        return `${interval.days} days${
            interval.hours ? ` ${interval.hours} hours` : ''
        }`;
    }

    if (interval.hours) {
        return `${interval.hours} hours${
            interval.minutes ? ` ${interval.minutes} minutes` : ''
        }`;
    }

    if (interval.minutes) {
        return `${interval.minutes} minutes${
            interval.seconds ? ` ${interval.seconds} seconds` : ''
        }`;
    }

    return `${interval.seconds} seconds`;
}

export function isoDateTimeString(date: Date) {
    return new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
    ).toISOString();
}

export function isoDateString(date: Date) {
    const str = isoDateTimeString(date);

    return str.split('T')[0];
}
