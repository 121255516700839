import { ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';

import { Accordion, AccordionProps } from './accordion';
import styles from './ui.module.scss';

export type JsonData = { [key: string]: string | number | null | undefined };

type UploadProps = {
    className: string;
    description?: string;
    onUploadFile: (files: File[], data?: JsonData) => void;
    onFileRejected?: (files: FileRejection[]) => void;
    extraData?: JsonData;
    uploaded?: boolean;
};

export const UploadWidget: React.FC<UploadProps> = ({
    className,
    description,
    onUploadFile,
    onFileRejected,
    extraData,
}) => {
    const initErrs: { file: string; error: string }[] = [];
    const [errors, setErrors] = useState(initErrs);

    const whenRejected = (files: FileRejection[]) => {
        onFileRejected?.(files);

        const fileErrors = files.map((file) => ({
            file: file.file.name,
            error: file.errors.map((err) => err.message).join('/n'),
        }));

        setErrors([...errors, ...fileErrors]);
    };

    const whenAccepted = (files: File[]) => {
        onUploadFile(files, extraData);
    };

    const onDrop = () => {
        setErrors([]);
    };
    return (
        <Dropzone
            onDropAccepted={whenAccepted}
            onDropRejected={whenRejected}
            onDrop={onDrop}
            accept={{
                'application/pdf': ['.pdf'],
                'image/*': ['.png', '.jpg', '.jpeg'],
            }}
            maxFiles={5}
            maxSize={3000000}
        >
            {/* @ts-ignore:next-line */}
            {({ getRootProps, getInputProps }) => (
                <section className={styles.upload + ' py-2 ' + className}>
                    {/* @ts-ignore:next-line */}
                    <div {...getRootProps()}>
                        {/* @ts-ignore:next-line */}
                        <input {...getInputProps()} />
                        <div
                            className={
                                styles.icon + ' flex flex-wrap justify-center'
                            }
                        >
                            <div>
                                <ArrowUpTrayIcon />
                            </div>
                        </div>
                        <div className={styles.text + ' w-full'}>
                            <p> {description}</p>
                            <p>
                                Drop some files here, or click to select files
                            </p>
                            <ul className="text-red-500">
                                {errors.map((error) => (
                                    <li key={error.file}>
                                        {error.file} could not be uploaded{' '}
                                        <br />
                                        {error.error.split('/n').map((err) => (
                                            <span key={err}>
                                                {err} <br />
                                            </span>
                                        ))}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </section>
            )}
        </Dropzone>
    );
};
type Props = Omit<AccordionProps, 'children'> & Omit<UploadProps, 'className'>;

export const UploadAccordion: React.FC<Props> = (props) => {
    return (
        <Accordion {...props}>
            <UploadWidget
                className={styles.dropzone}
                description={props.description}
                onUploadFile={props.onUploadFile}
                onFileRejected={props.onFileRejected}
                extraData={props.extraData}
                uploaded={props.uploaded}
            />
        </Accordion>
    );
};
