import { useState } from 'react';

import { useAppContext } from '@etica-js/api/src/appState/state';

import { Activities } from '../../components/widgets/activities';
import { BillsWidget } from '../../components/widgets/bills';
import { InvestmentsWidget } from '../../components/widgets/investments';
import { ProductsWidget } from '../../components/widgets/products';
import { QuickLinksWidget } from '../../components/widgets/quickLinks';

export const DashboardPage = () => {
    const appCtx = useAppContext();

    const hasAccounts = appCtx.process('GET_ACCOUNTS').length > 0;

    const [openModal, setOpenModal] = useState('');

    return (
        <>
            <QuickLinksWidget
                openModal={openModal}
                setOpenModal={setOpenModal}
            />
            {hasAccounts && (
                <>
                    <InvestmentsWidget />
                    <Activities />
                    <BillsWidget />
                </>
            )}
            <ProductsWidget />
        </>
    );
};
