import { decode } from 'base-64';
import { useEffect, useMemo } from 'react';

import { encrypt } from '@etica-js/utils/src/encryption';
import { keys } from '@etica-js/utils/src/functional';

import { useAuthContext } from '../auth/state';
import { setItem, getItem } from '../framework/storage';
import { debouncedFetchFeatures, debouncedFetchSettings } from './actions';
import { useAppContext } from './state';

const feature_list = {
    utility_bills: false,
    send_money: false,
    tokenless_login: false,
    mpesa_bills: false,
    disable_email_tokens: false,
    enable_transaction_pin: false,
};

type FeatureObj = typeof feature_list;
type FeatureName = keyof FeatureObj;

export const useSettings = (skipCredentials?: boolean) => {
    const appCtx = useAppContext();

    useEffect(() => {
        if (!appCtx.settings || appCtx.settings?.source === 'cache') {
            getItem('settings', true).then((data) => {
                data &&
                    appCtx.dispatch?.({
                        type: 'SET_SETTINGS',
                        payload: {
                            source: 'cache',
                            withAuth: !skipCredentials,
                            values: data,
                        },
                    });
            });

            debouncedFetchSettings(appCtx.dispatch, skipCredentials).then(
                (data) => {
                    if (data) {
                        setItem('settings', data, true);
                    }
                }
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appCtx.dispatch, skipCredentials]);
};

export const useFeatures = (skipCredentials?: boolean) => {
    const appCtx = useAppContext();

    useEffect(() => {
        const withAuth =
            appCtx.features.source === 'network' && !skipCredentials;

        if (
            appCtx.features.source !== 'network' &&
            withAuth !== appCtx.features.withAuth
        ) {
            debouncedFetchFeatures(appCtx.dispatch, skipCredentials);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appCtx.dispatch, skipCredentials]);

    const initial = { ...feature_list };

    // add values from cache if feature state is empty
    appCtx.features.source === undefined &&
        getItem<typeof feature_list>('features', true).then((cache) => {
            if (cache && appCtx.features.source !== 'network') {
                appCtx.dispatch({
                    type: 'SET_FEATURES',
                    payload: {
                        source: 'cache',
                        list: keys(cache).map((key) => ({
                            name: key,
                            enabled: cache[key],
                        })),
                    },
                });
            }
        });

    // override defaults with values from network
    appCtx.features.list?.forEach((feature) => {
        initial[feature.name as FeatureName] = feature.enabled ?? false;
    });

    return initial;
};

export const useTokenOptions = (except?: ReadonlyArray<string>) => {
    const { disable_email_tokens } = useFeatures();

    return useMemo(() => {
        const allOptions = [
            { label: 'SMS', value: 'sms' },
            { label: 'Email', value: 'email' },
            {
                label: 'Authenticator App',
                value: 'authenticator',
            },
        ].filter((el) => !(el.value in (except ?? [])));

        return disable_email_tokens
            ? allOptions.filter((opt) => opt.value !== 'email')
            : allOptions;
    }, [disable_email_tokens, except]);
};

export const useAppUtils = () => {
    const appCtx = useAppContext();

    return {
        can_encrypt: !!appCtx.settings?.values?.public_key,
        encrypt: (
            plaintext: string
        ): { encrypted: boolean; result: string } => {
            if (!appCtx.settings?.values?.public_key) {
                return { encrypted: false, result: plaintext };
            }

            const result = encrypt(
                plaintext,
                decode(appCtx.settings.values?.public_key)
            );

            return {
                encrypted: !!result,
                result: result || plaintext,
            };
        },
    };
};

export const useContextInitialization = () => {
    const { user } = useAuthContext();
    useSettings(!user?.username);
    useFeatures(!user?.username);
};
