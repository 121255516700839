import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { post } from '@etica-js/api/src/framework/http';

import form from '../../../assets/styles.module.scss';
import { LoadingAnimation } from '../../../components/ui/loading';
import styles from '../dashboard.module.scss';

export const AddNextOfKin = () => {
    const { code } = useParams();

    const [loading, setLoading] = useState(false);

    const [name, setName] = useState('');
    const [relationship, setRelationShip] = useState('');
    const [identity, setIdentity] = useState('');
    const [percentage, setPercentage] = useState(0);

    const navigate = useNavigate();

    const submit = (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);
        const data = {
            client_code: code ?? '',
            name,
            relationship,
            percentage,
            id_or_passport: identity,
        };

        post('client-api/next-of-kin', { data })
            .then((resp) => {
                if (resp.ok) {
                    toast.success('Next of kin saved successfully');
                    navigate('/dashboard/profile');
                    return;
                }

                resp.json().then((data) => {
                    toast.error(`An error occured - ${data?.Message}`);
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <div className="bg-white flex flex-wrap rounded px-2 py-2">
            <div className="w-full">
                <h3 className="text-lg font-semibold px-2 mb-5">
                    Add Next Of Kin
                </h3>
                <form
                    onSubmit={submit}
                    className={`w-full flex flex-wrap ${styles.fullpageForm}`}
                >
                    <div className={form.fieldset + ' w-full md:w-1/2 px-2'}>
                        <label htmlFor="">Name</label>
                        <input
                            type="text"
                            onChange={(e) => setName(e.currentTarget.value)}
                            required
                        />
                    </div>

                    <div className={form.fieldset + ' w-full md:w-1/2 px-2'}>
                        <label htmlFor="">Relationship</label>
                        <input
                            type="text"
                            onChange={(e) =>
                                setRelationShip(e.currentTarget.value)
                            }
                            required
                        />
                    </div>

                    <div className={form.fieldset + ' w-full md:w-1/2 px-2'}>
                        <label htmlFor="">
                            ID No./Passport No./Birth Certificate No.
                        </label>
                        <input
                            type="text"
                            onChange={(e) => setIdentity(e.currentTarget.value)}
                            required
                        />
                    </div>

                    <div className={form.fieldset + ' w-full md:w-1/2 px-2'}>
                        <label htmlFor="">Percentage</label>
                        <input
                            type="number"
                            onChange={(e) =>
                                setPercentage(parseInt(e.currentTarget.value))
                            }
                        />
                    </div>

                    <div className={form.fieldset + ' w-full px-2'}>
                        <button type="submit" className="primary button">
                            <LoadingAnimation loading={loading} /> Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};
