import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import React, { useEffect } from 'react';

import { fetchActivity } from '@etica-js/api/src/appState/actions';

import { Currency, DateFormat } from './formatting';
import styles from './widgets.module.scss';

type Data = {
    id: number;
    amount: number;
    date: string;
    narration: string;
    type: string;
    account: {
        client_name: string;
        product_name: string;
        product_currency: string;
    };
};
export const Activities = () => {
    const [activities, setActivities] = React.useState<Data[]>([]);
    const [previousPage, setPreviousPage] = React.useState<string | undefined>(
        undefined
    );
    const [nextPage, setNextPage] = React.useState<string | undefined>(
        undefined
    );

    useEffect(() => {
        fetchPage();
    }, []);
    const fetchPage = function (url?: string) {
        fetchActivity(undefined, 4, url).then((data) => {
            setActivities(data.data?.results);
            setPreviousPage(data.data?.previous);
            setNextPage(data.data?.next);
        });
    };
    return (
        <>
            <h3 className="text-xl font-bold mb-5">Recent Activities</h3>
            {activities?.length > 0 ? (
                <div className="flex flex-wrap">
                    {activities.map((data: Data) => (
                        <DisplayActivity key={data.id} data={data} />
                    ))}
                </div>
            ) : (
                <Loading />
            )}
            <div className="justify-items-end">
                {!!previousPage && (
                    <button
                        className="mr-5"
                        onClick={() => fetchPage(previousPage)}
                    >
                        <ChevronLeftIcon className="w-5" />
                    </button>
                )}

                {!!nextPage && (
                    <button onClick={() => fetchPage(nextPage)}>
                        <ChevronRightIcon className="w-5" />
                    </button>
                )}
            </div>
        </>
    );
};
export const DisplayActivity: React.FC<{ data: Data }> = (props) => {
    return (
        <div
            className={
                styles.activity +
                ' flex flex-wrap rounded mb-3 mr-3 w-full md:w-1/2 p-3'
            }
        >
            <div className="w-full justify-between flex flex-wrap">
                <div>{props.data.account.product_name}</div>
                <div>
                    <DateFormat date={props.data.date} />
                </div>
            </div>
            <div className="w-full flex flex-wrap justify-between">
                <div
                    className={
                        props.data.amount >= 0
                            ? 'text-green-500'
                            : 'text-red-500'
                    }
                >
                    {props.data.type}
                </div>
                <div>
                    <Currency
                        value={Math.abs(props.data.amount)}
                        currency={props.data.account.product_currency}
                    />
                </div>
            </div>
        </div>
    );
};

export const Loading = () => {
    return <>Loading...</>;
};
