import React, { createContext, useContext, useReducer } from 'react';

import { ILogger } from '@etica-js/utils/src/logger';
import { neverGuardDefault } from '@etica-js/utils/src/typing';

import {
    Client,
    Feature,
    InvestmentState,
    KycStatus,
    Product,
    Settings,
} from '../schema';

type Application = {
    type: string;
    client?: Client;
    referred_by?: string;
};

type State = {
    dispatch: AppStateDispatch;
    process: <T extends ProcessingAction>(action: T) => Processing[T];
    investments?: InvestmentState;
    loadingState?: 'loading' | 'loaded' | 'error';
    kycStatus?: { count: number; kyc?: KycStatus };
    application?: Application;
    products?: ReadonlyArray<Product>;
    features: {
        source?: 'cache' | 'network';
        list?: ReadonlyArray<Feature>;
        withAuth?: boolean;
    };
    settings?: Settings;
    ui: {
        openModal: { name: string; target: string };
    };
    logger?: ILogger;
};

export type AppStateDispatch = React.Dispatch<Action>;

export type Action =
    | { type: 'SET_INVESTMENT'; payload?: InvestmentState }
    | { type: 'SET_KYC_STATUS'; payload?: KycStatus }
    | { type: 'SET_APPLICATION'; payload?: Application }
    | { type: 'SET_PRODUCTS'; payload?: ReadonlyArray<Product> }
    | { type: 'SET_OPEN_MODAL'; payload: { name: string; target: string } }
    | { type: 'SET_FEATURES'; payload: State['features'] }
    | { type: 'SET_LOADING_STATE'; payload: 'loading' | 'loaded' | 'error' }
    | { type: 'SET_SETTINGS'; payload?: Settings };

const initialState: State = {
    ui: {
        openModal: { name: '', target: '' },
    },
    dispatch: (_: Action) => undefined,
    process: <T extends ProcessingAction>(action: T) =>
        undefined as unknown as Processing[T],
    features: {},
};
export const AppContext = createContext({ ...initialState });

export const useAppContext = () => {
    const context = useContext(AppContext);

    if (context === undefined) {
        throw new Error('App context was used outside its provider');
    }

    return context;
};

export function setAsyncState<T extends Action>(
    promise: Promise<T['payload']>,
    dispatch: React.Dispatch<Action> | undefined,
    type: T['type']
) {
    promise.then((payload) => {
        const action = { type, payload } as Action;

        return dispatch?.(action);
    });
}

export const AppWrapper: React.FC<{
    children: JSX.Element;
    logger?: ILogger;
}> = ({ children, logger }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    // @ts-ignore
    const process: State['process'] = <T extends ProcessingAction>(action: T) =>
        processor(state, action);

    return (
        <AppContext.Provider value={{ ...state, dispatch, process, logger }}>
            {children}
        </AppContext.Provider>
    );
};

function reducer(state: State, action: Action): State {
    const { type, payload } = action;
    let count = 0;

    switch (type) {
        case 'SET_INVESTMENT':
            return { ...state, investments: payload, loadingState: 'loaded' };
        case 'SET_LOADING_STATE':
            return { ...state, loadingState: payload };
        case 'SET_KYC_STATUS':
            payload?.forEach((client) => {
                const missing = client.missing_docs ?? [];
                let client_count = 0;
                client_count += missing.length;

                client?.joint_holder_docs?.forEach((holder) => {
                    client_count += holder.missing_docs.length;
                });

                client.count = client_count;
                count += client_count;

                return client;
            });
            return { ...state, kycStatus: { kyc: payload, count } };
        case 'SET_APPLICATION':
            return { ...state, application: payload };
        case 'SET_PRODUCTS':
            return { ...state, products: payload };
        case 'SET_OPEN_MODAL':
            return { ...state, ui: { ...state.ui, openModal: payload } };
        case 'SET_FEATURES':
            return { ...state, features: { ...state.features, ...payload } };
        case 'SET_SETTINGS':
            return { ...state, settings: payload };
        default:
            return neverGuardDefault(type, state);
    }
}
export type Account = InvestmentState[number]['accounts'][number] & {
    name: string;
    code: string;
    label: string;
    alias: string;
};

interface Processing {
    GET_ACCOUNTS: ReadonlyArray<Account>;
    CALCULATE_TOTAL: { currency: string; value: number };
}

type ProcessingAction = keyof Processing;

function processor<T extends ProcessingAction>(state: State, action: T) {
    switch (action) {
        case 'GET_ACCOUNTS':
            return fetchAccounts(state);
        case 'CALCULATE_TOTAL':
            return calculateInvestmentTotal(state);
        default:
            return neverGuardDefault(action, undefined);
    }
}

export const calculateInvestmentTotal = (state: State) => {
    let mv = 0;
    let mvBase = 0;
    const currencies = new Set();
    let base = '';

    state.investments?.forEach((client) => {
        client.accounts.forEach((acc) => {
            const val = acc.valuation;

            mv += val.market_value;
            mvBase += val.market_value_base_currency;
            currencies.add(acc.product.currency.code);
            base = val.base_currency;
        });
    });

    const useMv = currencies.size === 1;

    return {
        value: useMv ? mv : mvBase,
        currency: useMv ? (currencies.keys().next().value as string) : base,
    };
};

const fetchAccounts = (state: State) => {
    return (
        state.investments
            ?.map((c) =>
                c.accounts.map((acc) => ({
                    name: c.name,
                    code: c.code,
                    ...acc,
                    label: `${acc.product.short_name} \\ ${c.name} - ${acc.number}`,
                }))
            )
            .flat() ?? []
    );
};

export const APPLICATION_RESUME_ID = 'application-resume-id';
