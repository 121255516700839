import {
    ArrowUpOnSquareStackIcon,
    ShoppingBagIcon,
    SparklesIcon,
} from '@heroicons/react/24/outline';
import React from 'react';

import { useFeatures } from '@etica-js/api/src/appState/hooks';
import { useAppContext } from '@etica-js/api/src/appState/state';
import TopupIcon from '@etica-js/assets/src/icons/TopupIcon.svg';
import TransfersIcon from '@etica-js/assets/src/icons/TransfersIcon.svg';
import WithdrawIcon from '@etica-js/assets/src/icons/WithdrawIcon.svg';

import styles from './widgets.module.scss';

type ModalProps = {
    openModal: string;
    setOpenModal: (arg: string) => void;
};

export const QuickLinksWidget: React.FC<ModalProps> = (props) => {
    const appCtx = useAppContext();
    const features = useFeatures(false);

    const hasAccounts = appCtx.process('GET_ACCOUNTS').length > 0;
    const setOpenModal = (payload: string) => {
        appCtx.dispatch({
            type: 'SET_OPEN_MODAL',
            payload: { name: payload, target: '' },
        });
    };

    return (
        <div>
            <h3 className="text-xl font-bold mb-5">Quick Links</h3>

            <div className="flex flex-wrap">
                {!hasAccounts && (
                    <div
                        className={styles.quickLinksCard}
                        onClick={() => setOpenModal('invest')}
                    >
                        <SparklesIcon className="text-white" />
                        <p>Invest</p>
                    </div>
                )}
                {hasAccounts && (
                    <>
                        <div
                            className={styles.quickLinksCard}
                            onClick={() => setOpenModal('topup')}
                        >
                            <img src={TopupIcon} alt="" />
                            <p>Top-up</p>
                        </div>
                        <div
                            className={styles.quickLinksCard}
                            onClick={() => setOpenModal('withdraw')}
                        >
                            <img src={WithdrawIcon} alt="" />
                            <p>Withdraw</p>
                        </div>
                        {(features.utility_bills || features.mpesa_bills) && (
                            <div
                                className={styles.quickLinksCard}
                                onClick={() => setOpenModal('pay_bill')}
                            >
                                <ShoppingBagIcon />
                                <p>Pay bills</p>
                            </div>
                        )}
                        <div
                            className={styles.quickLinksCard}
                            onClick={() => setOpenModal('transfers')}
                        >
                            <img src={TransfersIcon} alt="" />
                            <p>Transfers</p>
                        </div>
                        {features.send_money && (
                            <div
                                className={styles.quickLinksCard}
                                onClick={() => setOpenModal('send-money')}
                            >
                                <ArrowUpOnSquareStackIcon />
                                <p>Send money</p>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};
