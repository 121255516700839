import { CheckBadgeIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';

import TriangleIcon from '@etica-js/assets/src/icons/Triangle.svg';

import styles from './ui.module.scss';

export type AsideProps = {
    children?: JSX.Element;
    onClose: () => void;
    success?: boolean;
    successComponent?: JSX.Element;
};

const AsideSuccess: React.FC<
    Pick<AsideProps, 'onClose' | 'successComponent'>
> = ({ onClose, successComponent }) => {
    return (
        <div className="flex flex-wrap p-5">
            <div className="flex justify-center align-middle w-full p-3">
                <CheckBadgeIcon height={150} className=" text-green-400" />
            </div>
            <div className="flex flex-wrap w-full align-middle text-center">
                <p className="w-full text-green-400">Saved successfully</p>

                {successComponent}
                <button
                    className="w-full secondary button mt-5"
                    onClick={onClose}
                >
                    Close
                </button>
            </div>
        </div>
    );
};

export const Aside: React.FC<AsideProps> = ({
    children,
    onClose,
    success,
    successComponent,
}) => {
    return (
        <div
            className={styles.actionAside + ' flex md:w-1/4 transition-shadow'}
        >
            <div className="flex">
                <div onClick={onClose} className={styles.close + ' '}>
                    <img src={TriangleIcon} alt="close popup" />
                </div>
            </div>
            <div className="flex flex-wrap content-start grow bg-white">
                <div
                    className={
                        styles.header +
                        ' flex flex-wrap w-full justify-end content-center'
                    }
                >
                    <button onClick={onClose} className="mr-5">
                        <XMarkIcon height={20} />
                    </button>
                </div>
                <div className="w-full grow overflow-y-auto bg-white">
                    <>
                        {!success && children}
                        {!!success && (
                            <AsideSuccess
                                onClose={onClose}
                                successComponent={successComponent}
                            />
                        )}
                    </>
                </div>
            </div>
        </div>
    );
};
