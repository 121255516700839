import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { fetchUser } from '@etica-js/api/src/auth/actions';
import { useAuthContext } from '@etica-js/api/src/auth/state';

import styles from '../assets/styles.module.scss';
import { NavBar } from '../components/navigation/navbar';

export const HomePage = () => {
    const authCtx = useAuthContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (!authCtx.user) {
            fetchUser().then((user) => {
                if (user) {
                    authCtx.dispatch?.({ type: 'FETCH', payload: user });
                    navigate('/dashboard');
                    return;
                }
                authCtx.dispatch?.({ type: 'FETCH', payload: undefined });
                navigate('/auth/login');
            });
        }
    }, [authCtx, navigate]);

    return (
        <div>
            <NavBar />
            <section
                className={`${styles.cover} flex flex-wrap justify-center items-center`}
            >
                <div className={`${styles.loginContainer}`}>
                    <div className={`${styles.loginSection} md:max-w-md`}>
                        <h3 className="text-3xl font-bold">
                            Welcome to Etica online platform
                        </h3>

                        <h5 className="mt-5 text-l font-semibold text-gray-600">
                            Select an option below
                        </h5>
                        <fieldset
                            className={styles.fieldset + ' mt-5 flex flex-wrap'}
                        >
                            <Link
                                className="primary button mr-2 grow"
                                to="/auth/login"
                            >
                                Proceed to login
                            </Link>
                            <Link className="secondary button grow" to="/apply">
                                Create an account
                            </Link>
                        </fieldset>
                    </div>
                </div>
            </section>
        </div>
    );
};
