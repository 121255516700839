import { AccType, getStages, useFormNavigate } from '..';
import React from 'react';
import { toast } from 'react-toastify';

import { useAppContext } from '@etica-js/api/src/appState/state';
import { get, post } from '@etica-js/api/src/framework/http';

import { ApplicationForm, Form, InputElementEvent } from '../form';

export type PersonalDetailsProps = {
    accountType: string;
};

export const fetchTitles = async () => {
    const resp = await get('client-api/titles');
    const data: ReadonlyArray<{ id: number; name: string }> = await resp.json();

    const labels = data?.map((d) => ({ value: d.id, label: d.name }));
    return [{ value: '', label: 'Select Title' }, ...labels];
};

type FormFields = Form['groups'][number]['fields'];

export const individualFields = (
    saveInput: (e: InputElementEvent, group: string) => void,
    accountType?: string
): FormFields => {
    const fields = [
        {
            name: 'title',
            label: 'Title',
            type: 'select',
            onChange: saveInput,
            options: fetchTitles,
        },
        {
            name: 'name',
            label: 'Name',
            children: [
                {
                    type: 'text',
                    name: 'first_name',
                    placeholder: 'First Name',
                    onChange: saveInput,
                },
                {
                    type: 'text',
                    name: 'middle_name',
                    placeholder: 'Middle Name',
                    onChange: saveInput,
                    optional: true,
                },
                {
                    type: 'text',
                    name: 'last_name',
                    placeholder: 'Last Name',
                    onChange: saveInput,
                },
            ],
        },
        {
            name: 'email',
            label: 'Email Address',
            placeholder: 'Email Address',
            type: 'email',
            onChange: saveInput,
        },
        {
            name: 'phone',
            label: 'Phone Number (include country code)',
            placeholder: 'Phone Number',
            type: 'tel',
            onChange: saveInput,
        },
        {
            name: 'id_or_passport',
            label:
                accountType === 'minor'
                    ? 'Birth certificate number'
                    : 'ID/Passport No',
            placeholder:
                accountType === 'minor'
                    ? 'Birth certificate number'
                    : 'ID or Passport No',
            type: 'text',
            onChange: saveInput,
        },
    ];

    const minorFields = [
        {
            name: 'parent_name',
            label: 'Parent/Guardian name',
            placeholder: 'Parent/Guardian name',
            type: 'text',
            onChange: saveInput,
        },
        {
            name: 'parent_id_or_passport',
            label: 'Parent/Guardian ID/Passport No',
            placeholder: 'Parent/Guardian ID/Passport No',
            type: 'text',
            onChange: saveInput,
        },
        {
            name: 'parent_phone',
            label: 'Parent/Guardian phone number',
            placeholder: 'Parent/Guardian phone number',
            type: 'tel',
            onChange: saveInput,
        },
    ];

    if (accountType === 'minor') {
        fields.push(...minorFields);
    }

    return fields as FormFields;
};

export const IndividualForm: React.FC<PersonalDetailsProps> = (props) => {
    const stages = getStages(props.accountType as AccType[number]['value']);
    const appCtx = useAppContext();

    const [formState, setFormState] = React.useState<{
        [key: string]: string | number;
    }>({});

    const navigate = useFormNavigate();

    const saveInput = (e: InputElementEvent) => {
        let state = formState;
        state[e.currentTarget.name] = e.currentTarget.value;
        setFormState(state);
    };

    const form: Form = {
        groups: [
            {
                name: 'individual',
                fields: individualFields(saveInput, props.accountType),
            },
        ],
    };

    const onSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        // @ts-ignore:next-line
        const source = window.APPLICATION_CLIENT ?? 'web';
        const referred_by = appCtx.application?.referred_by ?? '';

        const resp = await post('clients/self-register/individual', {
            data: {
                ...formState,
                classification: props.accountType,
                source,
                referred_by,
            },
        });

        const data = await resp.json();

        if (resp.ok) {
            toast.success('Saved successfully');
            navigate(`${data.code}/${data.key}/verify-phone`);
            return;
        }

        toast.error(`Failed - ${data?.Message}`);
    };

    return (
        <ApplicationForm
            stages={stages}
            currentStage="personal"
            form={form}
            onSubmit={onSubmit}
        />
    );
};
